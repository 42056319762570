import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, IconButton, Tooltip } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { lighten } from '@material-ui/core/styles/colorManipulator';

let EnhancedTableToolbar = props => {
  const { numSelected, classes, selected, orders } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          color="inherit"
          variant="button"
          style={{ color: '#00f557' }}
        >
          {numSelected} selected
        </Typography>
      ) : null}
      {numSelected > 0 ? (
        <React.Fragment>
          <Tooltip title="Print Labels">
            <Link
              to={{
                pathname: '/printOrderLabels',
                selected: selected,
                orders: orders,
              }}
            >
              <IconButton aria-label="Print">
                <PrintIcon style={{ color: '#00f557' }} />
              </IconButton>
            </Link>
          </Tooltip>
        </React.Fragment>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.success.main,
          backgroundColor: lighten(theme.palette.success.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.success.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.success,
  },
  title: {
    flex: '0 0 auto',
  },
});

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
