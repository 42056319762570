import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { createCsvOrders, clearErrors } from '../../actions/azdanActions';

class CsvCorrections extends Component {
  constructor() {
    super();
    this.state = {
      matrix: [],
      errors: [],
    };
    this.nameAutoFocus = true;
  }

  componentDidMount() {
    const matrix = this.props.csvErrorData || [];
    const errors = this.props.errors || [];
    this.setState(
      {
        matrix: matrix,
        errors: errors,
      },
      () => {
        if (this.state.errors.length) {
          errors.forEach(error => {
            let input = this[`${error.row}-${error.col}_ref`];
            input.classList.add('csv-error');
          });
          ReactDOM.findDOMNode(this).scrollIntoView();
        }
      }
    );
  }

  handleChange = (e, row, col) => {
    let newMatrix = this.state.matrix.slice();
    newMatrix[row][col] = e.target.value;
    this.setState({
      matrix: newMatrix,
    });
  };

  handleSubmitCsv = () => {
    this.props.createCsvOrders(this.state.matrix);
  };

  render() {
    let table = '';
    if (this.state.matrix.length) {
      const headerRow = this.state.matrix[0].slice();
      // get table rows
      const tableRows = this.state.matrix.slice(1, this.state.matrix.length);

      table = (
        <table>
          <thead>
            <tr>
              {headerRow.map((header, headerIndex) => (
                <th key={'th' + headerIndex}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableRows.map((row, rowIndex) => {
              return (
                <tr key={'tr' + rowIndex}>
                  {row.map((data, index) => (
                    <td key={'td' + index}>
                      <input
                        className="csv-error-input"
                        ref={input =>
                          (this[`${rowIndex + 1}-${index}_ref`] = input)
                        }
                        value={this.state.matrix[rowIndex + 1][index]}
                        onChange={e =>
                          this.handleChange(e, rowIndex + 1, index)
                        }
                        // autoFocus={this.nameAutoFocus}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    return (
      <>
        <div className="ml-4">
          <Button
            variant="contained"
            className="my-2 mx-1"
            onClick={() => (window.location.href = '/orderuploads')}
          >
            Cancel
          </Button>
          {/* <Button variant="contained" color="primary" className="my-2 mx-1">
            <SaveAlt />
            &nbsp;Save & Upload
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            className="my-2 mx-1"
            onClick={this.handleSubmitCsv}
          >
            <Send style={{ width: '18px' }} />
            &nbsp;Re-Send
          </Button>
        </div>
        <div className="csv-error-table">{table}</div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors.csvErrors,
    csvErrorData: state.errors.csvErrorData,
  };
};

export default connect(mapStateToProps, { createCsvOrders, clearErrors })(
  CsvCorrections
);
