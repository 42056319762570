export default statusNum => {
  let status = null;

  switch (statusNum) {
    case '1':
      status = 'Unassigned';

      break;

    case '2':
      status = 'Assigned';

      break;

    case '3':
      status = 'Completed';

      break;

    case '4':
      status = 'Cancelled';

      break;

    case '5':
      status = 'Failed';
      break;

    case '6':
      status = 'In Transit';

      break;

    case '7':
      status = 'Picked Up';

      break;

    case '9':
      status = 'Arrived in DC';

      break;

    case '10':
      status = 'Out for Delivery';

      break;

    case '15':
      status = 'Process for Return';
      break;

    case '16':
      status = 'Returned to Shipper';
      break;

    case '19':
      status = 'Duplicate';
      break;

    case '20':
      status = 'Returned to DC';
      break;

    case '21':
      status = 'Not Received';
      break;

    case '22':
      status = 'Rescheduled';
      break;

    case '23':
      status = 'Reattempt';
      break;

    default:
      status = 'Unknown';
      break;
  }

  return status;
};
