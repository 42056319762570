import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TableContainer from '../OrdersTable/TableContainer';
import Spinner from '../common/Spinner';
import { getOrders, logoutUser } from '../../actions/azdanActions';

class Orders extends Component {
  componentDidMount() {
    this.props.getOrders();

    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }
  }

  render() {
    const { order } = this.props;

    let content = null;
    // Show spinner if orders aren't fetched yet
    if (order.orderList === null) {
      content = <Spinner />;
    } else {
      content = <TableContainer orders={order.orderList} />;
    }

    return <div className="pt-1">{content}</div>;
  }
}

Orders.propTypes = {
  order: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  order: state.order,
});

export default connect(mapStateToProps, {
  getOrders,
  logoutUser,
})(Orders);
