import axios from 'axios';

import {
  GET_ALL_USERS,
  CLEAR_USERS,
  SUBMIT_USERS_REQUEST,
  RECEIVE_USERS_REPLY,
  GET_ERRORS,
  REGISTER_SUCCESS,
  SUBMIT_USERS_SUCCESS,
  RESET_USERS_SUCCESS,
  SHOW_ALERT,
} from './types';

// ////////////////
// Get all users
export const getAllUsers = () => dispatch => {
  axios.get('/api/users/all').then(res =>
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    })
  );
};

// /////////////
// Clear users
export const clearUsers = () => {
  return { type: CLEAR_USERS, payload: [] };
};

// ////////////////////////////////////
// @route  POST api/users/register
// @discrption  Register new user
// @access  Private
export const registerUser = userData => dispatch => {
  dispatch({ type: SUBMIT_USERS_REQUEST });

  axios
    .post('/api/users/register', userData)
    .then(res => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      dispatch({
        type: SHOW_ALERT,
        payload: `${res.data.name} was successfully created.`,
      });

      dispatch({ type: RECEIVE_USERS_REPLY });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({ type: RECEIVE_USERS_REPLY });
    });
};

// ////////////////////////////////////
// @route  PUT api/users/edit
// @discrption  Edit user
// @access  Private
export const editUser = userData => dispatch => {
  dispatch({ type: SUBMIT_USERS_REQUEST });

  axios
    .put('/api/users/edit', userData)
    .then(res => {
      dispatch({ type: RECEIVE_USERS_REPLY });

      dispatch({
        type: SHOW_ALERT,
        payload: `${res.data.name} was successfully edited.`,
      });

      dispatch({ type: SUBMIT_USERS_SUCCESS, payload: 'edit' });

      dispatch(getAllUsers());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({ type: RECEIVE_USERS_REPLY });
    });
};

// ////////////////////////////////////
// @route  PUT api/users/password
// @discrption  Change user password
// @access  Private
export const changePassword = userData => dispatch => {
  dispatch({ type: SUBMIT_USERS_REQUEST });

  axios
    .post('/api/users/password', userData)
    .then(res => {
      dispatch({ type: RECEIVE_USERS_REPLY });

      dispatch({ type: SUBMIT_USERS_SUCCESS, payload: 'password' });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({ type: RECEIVE_USERS_REPLY });
    });
};

// ////////////////////////////////////
// @route  GET api/users/userSettings
// @discrption  Change user password
// @access  Private
export const getUserSettings = () => dispatch => {
  axios
    .get('/api/users/userSettings')
    .then(res => {
      const { userSettings } = res.data;
      localStorage.setItem('queries', userSettings.queries);
      localStorage.setItem('savedCsvHeaders', userSettings.savedCsvHeaders);
      localStorage.setItem('columnsHidden', userSettings.columnsHidden);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// ////////////////////////////////////
// @route  PUT api/users/userSettings
// @discrption  Saves Users's Settings to DB
// @access  Private
export const updateUserSettings = (settingCol, newSettings) => dispatch => {
  const userSettings = [settingCol, newSettings];

  axios
    .put('/api/users/userSettings', userSettings)
    .then(res => {
      // console.log('saved userSettings: ', res);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const resetSuccess = () => dispatch => {
  dispatch({ type: RESET_USERS_SUCCESS });
};
