import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './App.css';
import './assets/css/material-dashboard-react.css';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import AuthRoute from './components/auth/AuthRoute';
import SuccessBar from './components/common/SuccessBar';
import ErrorBar from './components/common/ErrorBar';
import Navbar from './components/layout/Navbar';
import Login from './components/auth/Login';
import Orders from './components/Orders/Orders';
import OrderView from './components/Orders/OrderView';
import OrderCreate from './components/Orders/OrderCreate';
import Calendar from './components/Calendar/Calendar';
import OrderUploads from './components/OrderUploads/OrderUploads';
import CsvCorrections from './components/OrderUploads/CsvCorrections';
import Activity from './components/Activity/Activity';
import PrintOrderLabel from './components/PrintLabels/PrintOrderLabel';
import Reports from './components/Reports/Reports';

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    //  Redirect to login
    window.location.href = '/';

    persistor.purge();
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0d8cd1',
    },
  },
});

class App extends Component {
  render() {
    const state = store.getState();
    const isAuthenticated = state.auth.isAuthenticated;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <MuiThemeProvider theme={theme}>
              <div data-test="component-app" className="App">
                <Navbar />

                <div className="fade-in-2 container-fluid mobile-margin">
                  <Switch>
                    {/* Login */}
                    <Route exact path="/" component={Login} />
                    {/* 404 */}
                    {/* <Route render={() => <h1>Not found</h1>} /> */}
                  </Switch>

                  <Route
                    exact
                    path="/app"
                    render={() => (isAuthenticated ? <Redirect to="/orders" /> : <Redirect to="/" />)}
                  />
                  {/* Order management */}
                  <AuthRoute exact path="/orders" component={Orders} />

                  {/* Calendar */}
                  <AuthRoute exact path="/calendar" component={Calendar} />

                  {/* Activity */}
                  <AuthRoute exact path="/activity" component={Activity} />

                  {/* CSV Mapping */}
                  <AuthRoute exact path="/orderuploads" component={OrderUploads} />

                  {/* Print Labels */}
                  <AuthRoute exact path="/printOrderLabels" component={PrintOrderLabel} />
                  {/* Edit CSV Errors */}
                  <AuthRoute exact path="/csvCorrections" component={CsvCorrections} />
                  <AuthRoute path="/reports" component={Reports} />
                  <ErrorBar />
                  <SuccessBar />
                  <OrderView />
                  <OrderCreate />
                </div>
              </div>
            </MuiThemeProvider>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
