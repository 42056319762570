import moment from 'moment';

export const capitalizeFirstLetter = string => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1);
  }
};

export const formatDate = (date, dateFormat = 'YYYY-MM-DD') => {
  return moment(date).format(dateFormat);
};

export const formatTime = time => {
  return moment(time).format('HH:mm');
};

export const formatTimeAndDate = (date, dateFormat = 'YYYY-MM-DD HH:mm') => {
  return moment.utc(date).format(dateFormat);
};
