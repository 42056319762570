import React, { Component } from 'react';
import { connect } from 'react-redux';

import generateTaskClass from '../../utils/generateTaskClass';
import { openEditModal } from '../../actions/azdanActions';
import { logoutUser } from '../../actions/authActions';
import { clearAlert } from '../../actions/alertActions';

class ActivityItem extends Component {
  onOrderClick(order) {
    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }

    this.props.openEditModal(order);
    this.props.clearAlert();
  }

  navigate(view) {
    this.setState({ view: view });
  }

  render() {
    const {
      task_consigneename,
      task_consaddline1,
      task_conscity,
      task_consdistrict,
      task_status,
    } = this.props.order;
    const { order } = this.props;

    return (
      <div
        className={`list-group-item flex-column align-items-start mb-1 ${generateTaskClass(
          task_status
        )}`}
      >
        <div
          key={order._id}
          data-toggle="modal"
          data-target="#OrderView"
          onClick={() => this.onOrderClick(order)}
          className="fade-in pointer"
        >
          <div className="d-flex flex-column">
            <small className="text-truncate">
              Order For: {task_consigneename}
            </small>
            <small className="text-truncate">
              Address: {task_conscity}, {task_consdistrict}, {task_consaddline1}
            </small>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  openEditModal,
  logoutUser,
  clearAlert,
})(ActivityItem);
