import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, Button } from '@material-ui/core';
import { Create, Minimize, AspectRatio } from '@material-ui/icons';
import { clearErrors } from '../../actions/azdanActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    left: '26%',
    position: 'absolute',
    zIndex: '999',
    top: '5.4%',
    maxHeight: '90vh',
    overflow: 'auto',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    padding: '0 9px',
  },
  message: {
    width: '100%',
  },
  min: {
    height: '35px',
    borderRadius: '5px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  max: {
    height: 'fit-content',
  },
  minBtn: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    margin: '-2px 0 0 0',
    minWidth: '24px',
    padding: '1px',
    '&:hover': {
      backgroundColor: '#0d8cd1',
    },
  },
  btn: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    margin: '-7px 7px 0 0',
    '&:hover': {
      backgroundColor: '#0d8cd1',
    },
  },
  btnPrimary: {
    backgroundColor: '#3f51b5',
    border: 'none',
    fontWeight: '300 !important',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#0d8cd1',
    },
  },
}));

const CustomizedSnackbars = props => {
  const classes = useStyles();
  const [min, setMin] = React.useState(true);

  const handleClose = (event, reason) => {
    window.location.href = '/orderuploads';
    props.clearErrors();
    if (reason === 'clickaway') {
      return;
    }
    // setOpen(false);
  };

  let errorContent = null;
  if (props.errors) {
    errorContent = (
      <React.Fragment>
        {props.errors.map((error, i) => (
          <span key={`${i.toString()}error`}>
            {`${i + 1}. ${error.msg}`}
            <br />
          </span>
        ))}
      </React.Fragment>
    );
  }
  // props.errors.length > 20 adds another close button at the top of the dialog box so the user doesn't have to scroll to bottom to close error messages
  return (
    <div className={`${classes.root} ${min ? classes.max : classes.min}`}>
      <Collapse in={errorContent ? true : false}>
        <Alert severity="error" className={`${classes.alert}`} classes={{ message: classes.message }}>
          <div className="w-100 d-flex justify-content-between mb-2">
            <div>The following {props.errors.length} error(s) occured when trying to upload your CSV.</div>
            <div>
              <Button className={classes.minBtn} onClick={() => setMin(!min)}>
                {min ? <Minimize /> : <AspectRatio />}
              </Button>
            </div>
          </div>
          {props.errors.length > 20 && (
            <div>
              {props.csvData && (
                <Link to={'/csvCorrections'} onClick={() => setMin(!min)}>
                  <Button className={classes.btn} variant="outlined">
                    <Create />
                    &nbsp;Make CSV Corrections
                  </Button>
                </Link>
              )}
              <Button
                className={classes.btn}
                // variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          )}
          <br />
          <p data-test="error-text">{errorContent}</p>
          <br />
          <br />
          {props.csvData && (
            <Link to={'/csvCorrections'}>
              <Button className={classes.btn} variant="outlined" onClick={() => setMin(!min)}>
                <Create />
                &nbsp;Make CSV Corrections
              </Button>
            </Link>
          )}
          <Button className={classes.btn} variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Alert>
      </Collapse>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    errors: state.errors.csvErrors,
    csvData: state.errors.csvErrorData,
  };
};

export default connect(mapStateToProps, { clearErrors })(CustomizedSnackbars);
