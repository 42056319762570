module.exports = {
  Dubai: [
    { city: "Dubai", geofence: "Abu Hail" },
    { city: "Dubai", geofence: "Academic City" },
    { city: "Dubai", geofence: "Al Awir" },
    { city: "Dubai", geofence: "Al Bada'a" },
    { city: "Dubai", geofence: "Al Baraha" },
    { city: "Dubai", geofence: "Al Barsha " },
    { city: "Dubai", geofence: "Al Barsha " },
    { city: "Dubai", geofence: "Al Barsha " },
    { city: "Dubai", geofence: "Al Barsha South" },
    { city: "Dubai", geofence: "Al Dhagaya" },
    { city: "Dubai", geofence: "Al Furjan" },
    { city: "Dubai", geofence: "Al Garhoud" },
    { city: "Dubai", geofence: "Al Hamriya" },
    { city: "Dubai", geofence: "Al Hudaiba" },
    { city: "Dubai", geofence: "Al Jaddaf" },
    { city: "Dubai", geofence: "Al Jaffiliya" },
    { city: "Dubai", geofence: "Al Karama" },
    { city: "Dubai", geofence: "Al Khabaisi" },
    { city: "Dubai", geofence: "Al Khawaneej " },
    { city: "Dubai", geofence: "Al Khawaneej " },
    { city: "Dubai", geofence: "Al Mamzar" },
    { city: "Dubai", geofence: "Al Manara" },
    { city: "Dubai", geofence: "Al Mankhool" },
    { city: "Dubai", geofence: "Al Mina" },
    { city: "Dubai", geofence: "Al Mizhar " },
    { city: "Dubai", geofence: "Al Mizhar " },
    { city: "Dubai", geofence: "Al Muraqqabat" },
    { city: "Dubai", geofence: "Al Murar" },
    { city: "Dubai", geofence: "Al Mushrif" },
    { city: "Dubai", geofence: "Al Muteena" },
    { city: "Dubai", geofence: "Al Nahda " },
    { city: "Dubai", geofence: "Al Nahda " },
    { city: "Dubai", geofence: "Al Quoz " },
    { city: "Dubai", geofence: "Al Quoz " },
    { city: "Dubai", geofence: "Al Quoz " },
    { city: "Dubai", geofence: "Al Quoz " },
    { city: "Dubai", geofence: "Al Quoz Industrial Area " },
    { city: "Dubai", geofence: "Al Quoz Industrial Area " },
    { city: "Dubai", geofence: "Al Quoz Industrial Area " },
    { city: "Dubai", geofence: "Al Quoz Industrial Area " },
    { city: "Dubai", geofence: "Al Qusais " },
    { city: "Dubai", geofence: "Al Qusais " },
    { city: "Dubai", geofence: "Al Qusais " },
    { city: "Dubai", geofence: "Al Qusais Industrial Area " },
    { city: "Dubai", geofence: "Al Qusais Industrial Area " },
    { city: "Dubai", geofence: "Al Qusais Industrial Area " },
    { city: "Dubai", geofence: "Al Qusais Industrial Area " },
    { city: "Dubai", geofence: "Al Qusais Industrial Area " },
    { city: "Dubai", geofence: "Al Raffa" },
    { city: "Dubai", geofence: "Al Ras" },
    { city: "Dubai", geofence: "Al Rashidiya" },
    { city: "Dubai", geofence: "Al Rigga" },
    { city: "Dubai", geofence: "Al Sabkha" },
    { city: "Dubai", geofence: "Al Safa " },
    { city: "Dubai", geofence: "Al Safa" },
    { city: "Dubai", geofence: "Al Satwa" },
    { city: "Dubai", geofence: "Al Shindagha" },
    { city: "Dubai", geofence: "Al Souq Al Kabeer" },
    { city: "Dubai", geofence: "Al Sufouh " },
    { city: "Dubai", geofence: "Al Sufouh " },
    { city: "Dubai", geofence: "Al Twar " },
    { city: "Dubai", geofence: "Al Twar " },
    { city: "Dubai", geofence: "Al Twar " },
    { city: "Dubai", geofence: "Al Waheda" },
    { city: "Dubai", geofence: "Al Warqa " },
    { city: "Dubai", geofence: "Al Warqa " },
    { city: "Dubai", geofence: "Al Warqa " },
    { city: "Dubai", geofence: "Al Warqa " },
    { city: "Dubai", geofence: "Al Warqa " },
    { city: "Dubai", geofence: "Al Wasl" },
    { city: "Dubai", geofence: "Arabian Ranches " },
    { city: "Dubai", geofence: "Arabian Ranches" },
    { city: "Dubai", geofence: "Arjan" },
    { city: "Dubai", geofence: "Barsha Heights - TECOM" },
    { city: "Dubai", geofence: "Bukadra" },
    { city: "Dubai", geofence: "Business Bay" },
    { city: "Dubai", geofence: "Corniche Deira" },
    { city: "Dubai", geofence: "DIFC" },
    { city: "Dubai", geofence: "Discovery Gardens" },
    { city: "Dubai", geofence: "Downtown Dubai" },
    { city: "Dubai", geofence: "Downtown Jebel Ali" },
    { city: "Dubai", geofence: "Dubai Airport Freezone" },
    { city: "Dubai", geofence: "Dubai Cargo Village" },
    { city: "Dubai", geofence: "Dubai Design District" },
    { city: "Dubai", geofence: "Dubai Falcon City" },
    { city: "Dubai", geofence: "Dubai Festival City" },
    { city: "Dubai", geofence: "Dubai Healthcare City" },
    { city: "Dubai", geofence: "Dubai Hills" },
    { city: "Dubai", geofence: "Dubai International Airport" },
    { city: "Dubai", geofence: "Dubai Internet City - DIC" },
    { city: "Dubai", geofence: "Dubai Investments Park " },
    { city: "Dubai", geofence: "Dubai Investments Park " },
    { city: "Dubai", geofence: "Dubai Lifestyle City" },
    { city: "Dubai", geofence: "Dubai Marina" },
    { city: "Dubai", geofence: "Dubai Maritime City" },
    { city: "Dubai", geofence: "Dubai Media City" },
    { city: "Dubai", geofence: "Dubai Motor City" },
    { city: "Dubai", geofence: "Dubai Silicon Oasis" },
    { city: "Dubai", geofence: "Dubai Sports City" },
    { city: "Dubai", geofence: "Dubai Studio City" },
    { city: "Dubai", geofence: "Dubai Techno Park" },
    { city: "Dubai", geofence: "Dubai World Central - DWC" },
    { city: "Dubai", geofence: "Dubai World Trade Center - DWTC" },
    { city: "Dubai", geofence: "Emirates Hills" },
    { city: "Dubai", geofence: "Gardens" },
    { city: "Dubai", geofence: "Global Village" },
    { city: "Dubai", geofence: "Green Community Motor City" },
    { city: "Dubai", geofence: "Green Community" },
    { city: "Dubai", geofence: "Hatta" },
    { city: "Dubai", geofence: "Hor Al Anz East" },
    { city: "Dubai", geofence: "Hor Al Anz" },
    { city: "Dubai", geofence: "Ibn Batutta Mall" },
    { city: "Dubai", geofence: "IMPZ" },
    { city: "Dubai", geofence: "International City 2" },
    { city: "Dubai", geofence: "International City" },
    { city: "Dubai", geofence: "Jebel Ali 1" },
    { city: "Dubai", geofence: "Jebel Ali 2" },
    { city: "Dubai", geofence: "Jebel Ali Freezone Extension" },
    { city: "Dubai", geofence: "Jebel Ali Freezone" },
    { city: "Dubai", geofence: "Jebel Ali Industrial Area" },
    { city: "Dubai", geofence: "Jumeirah 1" },
    { city: "Dubai", geofence: "Jumeirah 2" },
    { city: "Dubai", geofence: "Jumeirah 3" },
    { city: "Dubai", geofence: "Jumeirah Beach Residence - JBR" },
    { city: "Dubai", geofence: "Jumeirah Golf Estates" },
    { city: "Dubai", geofence: "Jumeirah Heights" },
    { city: "Dubai", geofence: "Jumeirah Islands" },
    { city: "Dubai", geofence: "Jumeirah Lakes Towers - JLT" },
    { city: "Dubai", geofence: "Jumeirah Park" },
    { city: "Dubai", geofence: "Jumeirah Village Circle - JVC" },
    { city: "Dubai", geofence: "Jumeirah Village Triangle - JVT" },
    { city: "Dubai", geofence: "Knowledge Village" },
    { city: "Dubai", geofence: "Layan Community" },
    { city: "Dubai", geofence: "Legends" },
    { city: "Dubai", geofence: "Liwan" },
    { city: "Dubai", geofence: "Majan" },
    { city: "Dubai", geofence: "Mohammed Bin Rashed City" },
    { city: "Dubai", geofence: "Meadows" },
    { city: "Dubai", geofence: "Meydan South" },
    { city: "Dubai", geofence: "Mira Community" },
    { city: "Dubai", geofence: "Mirdif" },
    { city: "Dubai", geofence: "Mudon" },
    { city: "Dubai", geofence: "Muhaisnah 1" },
    { city: "Dubai", geofence: "Muhaisnah 2" },
    { city: "Dubai", geofence: "Muhaisnah 3" },
    { city: "Dubai", geofence: "Muhaisnah 4" },
    { city: "Dubai", geofence: "Nad Al Sheba 1" },
    { city: "Dubai", geofence: "Nad Al Sheba 2" },
    { city: "Dubai", geofence: "Nad Al Sheba 3" },
    { city: "Dubai", geofence: "Nad Al Sheba 4" },
    { city: "Dubai", geofence: "Nadd Al Hamar" },
    { city: "Dubai", geofence: "Nadd Al Shamma" },
    { city: "Dubai", geofence: "Naif" },
    { city: "Dubai", geofence: "Oud Al Muteena" },
    { city: "Dubai", geofence: "Oud Metha" },
    { city: "Dubai", geofence: "Port Rashid" },
    { city: "Dubai", geofence: "Port Saeed" },
    { city: "Dubai", geofence: "Ras Al Khor Indsutrial Area 1" },
    { city: "Dubai", geofence: "Ras Al Khor Indsutrial Area 2" },
    { city: "Dubai", geofence: "Ras Al Khor Indsutrial Area 3" },
    { city: "Dubai", geofence: "Ras Al Khor" },
    { city: "Dubai", geofence: "Remraam" },
    { city: "Dubai", geofence: "Rigga Al Buteen" },
    { city: "Dubai", geofence: "Springs" },
    { city: "Dubai", geofence: "The Greens" },
    { city: "Dubai", geofence: "The Lakes" },
    { city: "Dubai", geofence: "The Palm Deira" },
    { city: "Dubai", geofence: "The Palm Jebel Ali" },
    { city: "Dubai", geofence: "Palm Jumeirah" },
    { city: "Dubai", geofence: "The Skycourts" },
    { city: "Dubai", geofence: "The Sustainable City" },
    { city: "Dubai", geofence: "The Villa" },
    { city: "Dubai", geofence: "Um Al Sheif" },
    { city: "Dubai", geofence: "Umm Hurair 1" },
    { city: "Dubai", geofence: "Umm Hurair 2" },
    { city: "Dubai", geofence: "Umm Ramool" },
    { city: "Dubai", geofence: "Umm Suqeim 1" },
    { city: "Dubai", geofence: "Umm Suqeim 2" },
    { city: "Dubai", geofence: "Umm Suqeim 3" },
    { city: "Dubai", geofence: "Wadi Alamardi" },
    { city: "Dubai", geofence: "Warsan 1" },
    { city: "Dubai", geofence: "Warsan 2" },
    { city: "Dubai", geofence: "Waterfront" },
    { city: "Dubai", geofence: "Zaabeel 1" },
    { city: "Dubai", geofence: "Zaabeel 2" },
    { city: "Dubai", geofence: "Akoya Oxygen " },
    { city: "Dubai", geofence: "Deira Water Front" },
    { city: "Dubai", geofence: "Townsquare" },
    { city: "Dubai", geofence: "Jebel Ali Village" },
    { city: "Dubai", geofence: "Al Muntazah" }
  ],
  "Abu Dhabi": [
    { city: "Abu Dhabi", geofence: "Abu Dhabi Gate City" },
    { city: "Abu Dhabi", geofence: "Abu Dhabi Hills" },
    { city: "Abu Dhabi", geofence: "Abu Dhabi International Airport" },
    { city: "Abu Dhabi", geofence: "Al Adla " },
    { city: "Abu Dhabi", geofence: "Al Aman" },
    { city: "Abu Dhabi", geofence: "Al Bandar" },
    { city: "Abu Dhabi", geofence: "Al Dafrah" },
    { city: "Abu Dhabi", geofence: "Al Etihad- Al Nahyan " },
    { city: "Abu Dhabi", geofence: "Al Falah City" },
    { city: "Abu Dhabi", geofence: "Al Forsan Village" },
    { city: "Abu Dhabi", geofence: "Al Gurum Resort" },
    { city: "Abu Dhabi", geofence: "Al Hosn" },
    { city: "Abu Dhabi", geofence: "Al Karamah" },
    { city: "Abu Dhabi", geofence: "Al Khalidiyah" },
    { city: "Abu Dhabi", geofence: "Al Khubeirah" },
    { city: "Abu Dhabi", geofence: "Al Mafraq" },
    { city: "Abu Dhabi", geofence: "Al Manhal" },
    { city: "Abu Dhabi", geofence: "Al Maqta" },
    { city: "Abu Dhabi", geofence: "Al Markaziyah West" },
    { city: "Abu Dhabi", geofence: "Al Markaziyah" },
    { city: "Abu Dhabi", geofence: "Al Maryah Island" },
    { city: "Abu Dhabi", geofence: "Al Mina" },
    { city: "Abu Dhabi", geofence: "Al Muneera (Al Raha)" },
    { city: "Abu Dhabi", geofence: "Al Muntazah" },
    { city: "Abu Dhabi", geofence: "Al Musalla" },
    { city: "Abu Dhabi", geofence: "Al Mushrif" },
    { city: "Abu Dhabi", geofence: "Al Muzoon" },
    { city: "Abu Dhabi", geofence: "Al Nahyan" },
    { city: "Abu Dhabi", geofence: "Al Raha Beach" },
    { city: "Abu Dhabi", geofence: "Al Raha" },
    { city: "Abu Dhabi", geofence: "Al Rahba" },
    { city: "Abu Dhabi", geofence: "Al Ras Al Akhdar" },
    { city: "Abu Dhabi", geofence: "Al Rayyana" },
    { city: "Abu Dhabi", geofence: "Al Reef" },
    { city: "Abu Dhabi", geofence: "Al Rowdah" },
    { city: "Abu Dhabi", geofence: "Al Ruwais" },
    { city: "Abu Dhabi", geofence: "Al Sader" },
    { city: "Abu Dhabi", geofence: "Al Safarrat" },
    { city: "Abu Dhabi", geofence: "Al Samha" },
    { city: "Abu Dhabi", geofence: "Al Shaleela" },
    { city: "Abu Dhabi", geofence: "Al Shamkha" },
    { city: "Abu Dhabi", geofence: "Al Shawamekh" },
    { city: "Abu Dhabi", geofence: "Al Wahdah" },
    { city: "Abu Dhabi", geofence: "Al Wathba" },
    { city: "Abu Dhabi", geofence: "Al Zaab" },
    { city: "Abu Dhabi", geofence: "Al Zafranah" },
    { city: "Abu Dhabi", geofence: "Al Zahraa" },
    { city: "Abu Dhabi", geofence: "Al Zeina (Al Raha)" },
    { city: "Abu Dhabi", geofence: "Bani Yas East" },
    { city: "Abu Dhabi", geofence: "Bani Yas West" },
    { city: "Abu Dhabi", geofence: "BreakWater" },
    { city: "Abu Dhabi", geofence: "Capital Centre" },
    { city: "Abu Dhabi", geofence: "Golf Gardens" },
    { city: "Abu Dhabi", geofence: "Hydra Village (Shahama)" },
    { city: "Abu Dhabi", geofence: "ICAD" },
    { city: "Abu Dhabi", geofence: "Khor Al Bateen" },
    { city: "Abu Dhabi", geofence: "Madinat Al Riyad" },
    { city: "Abu Dhabi", geofence: "Madinat Khalifa - A" },
    { city: "Abu Dhabi", geofence: "Madinat Khalifa - C" },
    { city: "Abu Dhabi", geofence: "Madinat Zayed" },
    { city: "Abu Dhabi", geofence: "Mahwi" },
    { city: "Abu Dhabi", geofence: "Masdar City" },
    { city: "Abu Dhabi", geofence: "Mohammed Bin Zayed City" },
    { city: "Abu Dhabi", geofence: "Mussafah" },
    { city: "Abu Dhabi", geofence: "New Shahama" },
    { city: "Abu Dhabi", geofence: "Old Bahya" },
    { city: "Abu Dhabi", geofence: "Port Zayed (Al Mina)" },
    { city: "Abu Dhabi", geofence: "Qasr El Bahr" },
    { city: "Abu Dhabi", geofence: "Qasr El Shatie" },
    { city: "Abu Dhabi", geofence: "Reem Island" },
    { city: "Abu Dhabi", geofence: "Saadiyat Island" },
    { city: "Abu Dhabi", geofence: "Sas Al Nakheel (Khalifa - A)" },
    { city: "Abu Dhabi", geofence: "Sas Al Nakhl Island" },
    { city: "Abu Dhabi", geofence: "Shahama" },
    { city: "Abu Dhabi", geofence: "Shakhbout City" },
    { city: "Abu Dhabi", geofence: "Tourist Club Area (Al Zahiya)" },
    { city: "Abu Dhabi", geofence: "Yas Island" },
    { city: "Abu Dhabi", geofence: "Zayed City (Western Region)" },
    { city: "Abu Dhabi", geofence: "Zayed Sports City" },
    { city: "Abu Dhabi", geofence: "Madinat Khalifa - B" },
    { city: "Abu Dhabi", geofence: "Al Bahia" }
  ],
  Sharjah: [
    { city: "Sharjah", geofence: "Abu Shagara" },
    { city: "Sharjah", geofence: "Al Muntazah" },
    { city: "Sharjah", geofence: "Al Abar" },
    { city: "Sharjah", geofence: "Al Atain" },
    { city: "Sharjah", geofence: "Al Azra" },
    { city: "Sharjah", geofence: "Al Bdai'a Subrub" },
    { city: "Sharjah", geofence: "Al Darari" },
    { city: "Sharjah", geofence: "Al Dhaid" },
    { city: "Sharjah", geofence: "Al Elyash" },
    { city: "Sharjah", geofence: "Al Falah Suburb" },
    { city: "Sharjah", geofence: "Al Falaj" },
    { city: "Sharjah", geofence: "Al Fayha" },
    { city: "Sharjah", geofence: "Al Fisht" },
    { city: "Sharjah", geofence: "Al Ghaphia" },
    { city: "Sharjah", geofence: "Al Gharayen" },
    { city: "Sharjah", geofence: "Al Ghubaiba" },
    { city: "Sharjah", geofence: "Al Ghuwair" },
    { city: "Sharjah", geofence: "Al Goaz" },
    { city: "Sharjah", geofence: "Al Hazana" },
    { city: "Sharjah", geofence: "Al Homaa" },
    { city: "Sharjah", geofence: "Al Jazzat" },
    { city: "Sharjah", geofence: "Al Jlail" },
    { city: "Sharjah", geofence: "Al Jubail" },
    { city: "Sharjah", geofence: "Al Juraina" },
    { city: "Sharjah", geofence: "Al Khaledia Suburb" },
    { city: "Sharjah", geofence: "Al Khan" },
    { city: "Sharjah", geofence: "Al Khezamia" },
    { city: "Sharjah", geofence: "Al Layyah" },
    { city: "Sharjah", geofence: "Al Mahatta" },
    { city: "Sharjah", geofence: "Al Majaz 1" },
    { city: "Sharjah", geofence: "Al Majaz 2" },
    { city: "Sharjah", geofence: "Al Majaz 3" },
    { city: "Sharjah", geofence: "Al Mamzar" },
    { city: "Sharjah", geofence: "Al Manakh" },
    { city: "Sharjah", geofence: "Al Manama" },
    { city: "Sharjah", geofence: "Al Manazel" },
    { city: "Sharjah", geofence: "Al Mansura" },
    { city: "Sharjah", geofence: "Al Mareija" },
    { city: "Sharjah", geofence: "Al Mirgab" },
    { city: "Sharjah", geofence: "Al Mujarah" },
    { city: "Sharjah", geofence: "Al Musalla" },
    { city: "Sharjah", geofence: "Al Nabba" },
    { city: "Sharjah", geofence: "Al Nad" },
    { city: "Sharjah", geofence: "Al Nahda" },
    { city: "Sharjah", geofence: "Al Nasserya" },
    { city: "Sharjah", geofence: "Al Nekhailat" },
    { city: "Sharjah", geofence: "Al Noof" },
    { city: "Sharjah", geofence: "Al Qadisia" },
    { city: "Sharjah", geofence: "Al Qasimia" },
    { city: "Sharjah", geofence: "Al Qulayaa" },
    { city: "Sharjah", geofence: "Al Rahmania" },
    { city: "Sharjah", geofence: "Al Ramaqia" },
    { city: "Sharjah", geofence: "Al Ramla East" },
    { city: "Sharjah", geofence: "Al Ramla West" },
    { city: "Sharjah", geofence: "Al Ramtha" },
    { city: "Sharjah", geofence: "Al Rifah" },
    { city: "Sharjah", geofence: "Al Sabkha" },
    { city: "Sharjah", geofence: "Al Saja'a Industrial Subrub" },
    { city: "Sharjah", geofence: "Al Seef" },
    { city: "Sharjah", geofence: "Al Seyouh" },
    { city: "Sharjah", geofence: "Al Shahba" },
    { city: "Sharjah", geofence: "Al Shuwaihen" },
    { city: "Sharjah", geofence: "Al Soor" },
    { city: "Sharjah", geofence: "Al Sweihat" },
    { city: "Sharjah", geofence: "Al Taawun" },
    { city: "Sharjah", geofence: "Al Talaa" },
    { city: "Sharjah", geofence: "Al Tarfa" },
    { city: "Sharjah", geofence: "Al Yarmook" },
    { city: "Sharjah", geofence: "Barashi" },
    { city: "Sharjah", geofence: "Bu Danig" },
    { city: "Sharjah", geofence: "Bu Tina" },
    { city: "Sharjah", geofence: "Dasman" },
    { city: "Sharjah", geofence: "Industrial Area 1" },
    { city: "Sharjah", geofence: "Industrial Area 10" },
    { city: "Sharjah", geofence: "Industrial Area 11" },
    { city: "Sharjah", geofence: "Industrial Area 12" },
    { city: "Sharjah", geofence: "Industrial Area 13" },
    { city: "Sharjah", geofence: "Industrial Area 15" },
    { city: "Sharjah", geofence: "Industrial Area 17" },
    { city: "Sharjah", geofence: "Industrial Area 18" },
    { city: "Sharjah", geofence: "Industrial Area 2" },
    { city: "Sharjah", geofence: "Industrial Area 3" },
    { city: "Sharjah", geofence: "Industrial Area 4" },
    { city: "Sharjah", geofence: "Industrial Area 5" },
    { city: "Sharjah", geofence: "Industrial Area 6" },
    { city: "Sharjah", geofence: "Industrial Area 7" },
    { city: "Sharjah", geofence: "Industrial Area 8" },
    { city: "Sharjah", geofence: "Industrial Area 9" },
    { city: "Sharjah", geofence: "Maysaloon" },
    { city: "Sharjah", geofence: "Muwafjah" },
    { city: "Sharjah", geofence: "Muwaileh Commercial" },
    { city: "Sharjah", geofence: "Muzairah" },
    { city: "Sharjah", geofence: "Rolla" },
    { city: "Sharjah", geofence: "Saif Zone" },
    { city: "Sharjah", geofence: "Samnan" },
    { city: "Sharjah", geofence: "Sharqan" },
    { city: "Sharjah", geofence: "Um Altaraffa" },
    { city: "Sharjah", geofence: "Um Fannain" },
    { city: "Sharjah", geofence: "University of Sharjah" }
  ],
  Ajman: [
    { city: "Ajman", geofence: "Ajman City Center" },
    { city: "Ajman", geofence: "Ajman Corniche" },
    { city: "Ajman", geofence: "Ajman Free Zone" },
    { city: "Ajman", geofence: "Ajman Industrial Area 1" },
    { city: "Ajman", geofence: "Ajman Industrial Area 2" },
    { city: "Ajman", geofence: "Al Alia" },
    { city: "Ajman", geofence: "Al Bustan" },
    { city: "Ajman", geofence: "Al Butain" },
    { city: "Ajman", geofence: "Al Hamidiya 1" },
    { city: "Ajman", geofence: "Al Hamidiya 2" },
    { city: "Ajman", geofence: "Al Hamidiya" },
    { city: "Ajman", geofence: "Al Hamriyah Free Zone" },
    { city: "Ajman", geofence: "Al Helio" },
    { city: "Ajman", geofence: "Al Heliow 1" },
    { city: "Ajman", geofence: "Al Heliow 2" },
    { city: "Ajman", geofence: "Al Jurf 1" },
    { city: "Ajman", geofence: "Al Jurf 2" },
    { city: "Ajman", geofence: "Al Jurf Industrial Area 1" },
    { city: "Ajman", geofence: "Al Jurf Industrial Area 2" },
    { city: "Ajman", geofence: "Al Jurf Industrial Area 3" },
    { city: "Ajman", geofence: "Al Muntazi 1" },
    { city: "Ajman", geofence: "Al Muntazi 2" },
    { city: "Ajman", geofence: "Al Mushairef" },
    { city: "Ajman", geofence: "Al Mwaihat 1" },
    { city: "Ajman", geofence: "Al Mwaihat 2" },
    { city: "Ajman", geofence: "Al Mwaihat 3" },
    { city: "Ajman", geofence: "Al Nakhil 1" },
    { city: "Ajman", geofence: "Al Nakhil 2" },
    { city: "Ajman", geofence: "Al Nakhil" },
    { city: "Ajman", geofence: "Al Nuaimia 1" },
    { city: "Ajman", geofence: "Al Nuaimia 2" },
    { city: "Ajman", geofence: "Al Raqaib 1" },
    { city: "Ajman", geofence: "Al Raqaib 2" },
    { city: "Ajman", geofence: "Al Rashidiya 1" },
    { city: "Ajman", geofence: "Al Rashidiya 2" },
    { city: "Ajman", geofence: "Al Rashidiya 3" },
    { city: "Ajman", geofence: "Al Rawda 1 Al Zahraa" },
    { city: "Ajman", geofence: "Al Rawda 2 Al Zahraa" },
    { city: "Ajman", geofence: "Al Rawda 3 Al Zahraa" },
    { city: "Ajman", geofence: "Al Rumailah" },
    { city: "Ajman", geofence: "Al Tallah 1" },
    { city: "Ajman", geofence: "Al Tallah 2" },
    { city: "Ajman", geofence: "Al Yasmeen" },
    { city: "Ajman", geofence: "Al Zahya" },
    { city: "Ajman", geofence: "Al Zawarah" },
    { city: "Ajman", geofence: "Al Zora" },
    { city: "Ajman", geofence: "Emirates City" },
    { city: "Ajman", geofence: "Garden City" },
    { city: "Ajman", geofence: "Ittihad Village" }
  ],
  "Al Ain": [
    { city: "Al Ain", geofence: "Abu Samrah" },
    { city: "Al Ain", geofence: "Al Agabiyya" },
    { city: "Al Ain", geofence: "Al Ain International Airport" },
    { city: "Al Ain", geofence: "Al Ain Zoo" },
    { city: "Al Ain", geofence: "Al Ameriya" },
    { city: "Al Ain", geofence: "Al Dhahir" },
    { city: "Al Ain", geofence: "Al Foaa" },
    { city: "Al Ain", geofence: "Al Hili" },
    { city: "Al Ain", geofence: "Al Jahili" },
    { city: "Al Ain", geofence: "Al Jimi" },
    { city: "Al Ain", geofence: "Al Khabisi" },
    { city: "Al Ain", geofence: "Al Kuwaitat" },
    { city: "Al Ain", geofence: "Al Manasir" },
    { city: "Al Ain", geofence: "Al Maqam" },
    { city: "Al Ain", geofence: "Al Marakhaniya" },
    { city: "Al Ain", geofence: "Al Masoudi" },
    { city: "Al Ain", geofence: "Al Murabaa" },
    { city: "Al Ain", geofence: "Al Muraijeb" },
    { city: "Al Ain", geofence: "Al Mutarad" },
    { city: "Al Ain", geofence: "Al Mutawaa" },
    { city: "Al Ain", geofence: "Al Muwaiji" },
    { city: "Al Ain", geofence: "Al Nabagh" },
    { city: "Al Ain", geofence: "Al Neyadat" },
    { city: "Al Ain", geofence: "Al Qattara" },
    { city: "Al Ain", geofence: "Al Sad" },
    { city: "Al Ain", geofence: "Al Salamat District" },
    { city: "Al Ain", geofence: "Al Sarooj" },
    { city: "Al Ain", geofence: "Al Towayya" },
    { city: "Al Ain", geofence: "Al Yahar" },
    { city: "Al Ain", geofence: "Alain Al Fiyada" },
    { city: "Al Ain", geofence: "Asharej" },
    { city: "Al Ain", geofence: "Bedda Bn Ammar" },
    { city: "Al Ain", geofence: "Falaj Hazzaa" },
    { city: "Al Ain", geofence: "Industrial Area" },
    { city: "Al Ain", geofence: "Khatim Al Shikla" },
    { city: "Al Ain", geofence: "Mezyed" },
    { city: "Al Ain", geofence: "Green Mubazzarah" },
    { city: "Al Ain", geofence: "Neima" },
    { city: "Al Ain", geofence: "Remah" },
    { city: "Al Ain", geofence: "Tawam" },
    { city: "Al Ain", geofence: "UAE University" },
    { city: "Al Ain", geofence: "Um Ghaffa" },
    { city: "Al Ain", geofence: "Zakher" },
    { city: "Al Ain", geofence: "Hai Khalid" },
    { city: "Al Ain", geofence: "Shab Al Ashkar" },
    { city: "Al Ain", geofence: "jabal hafeet" },
    { city: "Al Ain", geofence: "Al Salamat" },
    { city: "Al Ain", geofence: "Al 'Iqabiyyah" },
    { city: "Al Ain", geofence: "albateen" },
    { city: "Al Ain", geofence: "Shabhanet Ashrej" },
    { city: "Al Ain", geofence: "Asharej" },
    { city: "Al Ain", geofence: "Al Khaznah" },
    { city: "Al Ain", geofence: "AL Ajban " },
    { city: "Al Ain", geofence: "Sweihan " },
    { city: "Al Ain", geofence: "Al Faqa" },
    { city: "Al Ain", geofence: "Nahil " },
    { city: "Al Ain", geofence: "Mondafanah" },
    { city: "Al Ain", geofence: "Military Zone" },
    { city: "Al Ain", geofence: "Malaqit" },
    { city: "Al Ain", geofence: "Al Nabghah" },
    { city: "Al Ain", geofence: "Al Qisees" }
  ],
  Fujairah: [
    { city: "Fujairah", geofence: "Al Aqah" },
    { city: "Fujairah", geofence: "Al Bedia" },
    { city: "Fujairah", geofence: "Al Buthna" },
    { city: "Fujairah", geofence: "Al Farfar" },
    { city: "Fujairah", geofence: "Al Faseel" },
    { city: "Fujairah", geofence: "Al Hala- Restricted " },
    { city: "Fujairah", geofence: "Al Haray" },
    { city: "Fujairah", geofence: "Al Heel" },
    { city: "Fujairah", geofence: "Al Ittihad" },
    { city: "Fujairah", geofence: "Al Mahatta" },
    { city: "Fujairah", geofence: "Al Owaid" },
    { city: "Fujairah", geofence: "Al Qurayya - Restricted " },
    { city: "Fujairah", geofence: "Al Taween - Restricted" },
    { city: "Fujairah", geofence: "Albeledah" },
    { city: "Fujairah", geofence: "Anajaimat" },
    { city: "Fujairah", geofence: "Awhala" },
    { city: "Fujairah", geofence: "Dedna" },
    { city: "Fujairah", geofence: "Dibba Al Hisn" },
    { city: "Fujairah", geofence: "Dibba Industrial Area" },
    { city: "Fujairah", geofence: "Dibba" },
    { city: "Fujairah", geofence: "Fujairah Free Trade Zone" },
    { city: "Fujairah", geofence: "Fujairah Intl Airport" },
    { city: "Fujairah", geofence: "Fujairah Port" },
    { city: "Fujairah", geofence: "Halefaath" },
    { city: "Fujairah", geofence: "Industrial Area" },
    { city: "Fujairah", geofence: "Kalba" },
    { city: "Fujairah", geofence: "Karsha- Restricted" },
    { city: "Fujairah", geofence: "Khor Fakkan" },
    { city: "Fujairah", geofence: "Madhab" },
    { city: "Fujairah", geofence: "Masafi" },
    { city: "Fujairah", geofence: "Merashid" },
    { city: "Fujairah", geofence: "Mirbah" },
    { city: "Fujairah", geofence: "Old Fujairah City" },
    { city: "Fujairah", geofence: "Qidfa" },
    { city: "Fujairah", geofence: "Rughaylat" },
    { city: "Fujairah", geofence: "Rumailah" },
    { city: "Fujairah", geofence: "Sakamkam" },
    { city: "Fujairah", geofence: "Sharm" },
    { city: "Fujairah", geofence: "Suwayfah " },
    { city: "Fujairah", geofence: "Zubara" }
  ],
  "Ras Al Khaima": [
    { city: "Ras Al Khaima", geofence: "Al Darbijaniyah" },
    { city: "Ras Al Khaima", geofence: "Al Dhait North" },
    { city: "Ras Al Khaima", geofence: "Al Dhait South" },
    { city: "Ras Al Khaima", geofence: "Al Duhaisah" },
    { city: "Ras Al Khaima", geofence: "Al Fahlain" },
    { city: "Ras Al Khaima", geofence: "Al Hamra Free Zone" },
    { city: "Ras Al Khaima", geofence: "Al Hamra Industrial Area" },
    { city: "Ras Al Khaima", geofence: "Al Hamra Island" },
    { city: "Ras Al Khaima", geofence: "Al Hamra Village" },
    { city: "Ras Al Khaima", geofence: "Al Hudaibah" },
    { city: "Ras Al Khaima", geofence: "Al Jazeerah Al Hamra" },
    { city: "Ras Al Khaima", geofence: "Al Jesser Area" },
    { city: "Ras Al Khaima", geofence: "Al Juwais Area" },
    { city: "Ras Al Khaima", geofence: "Al Kharran" },
    { city: "Ras Al Khaima", geofence: "Al Mairid" },
    { city: "Ras Al Khaima", geofence: "Al Marjan Island" },
    { city: "Ras Al Khaima", geofence: "Al Mataf" },
    { city: "Ras Al Khaima", geofence: "Al Nadiyah" },
    { city: "Ras Al Khaima", geofence: "Al Nakheel Area" },
    { city: "Ras Al Khaima", geofence: "Al Nudood" },
    { city: "Ras Al Khaima", geofence: "Al Qurm" },
    { city: "Ras Al Khaima", geofence: "Al Qusaidat" },
    { city: "Ras Al Khaima", geofence: "Al Ragibah" },
    { city: "Ras Al Khaima", geofence: "Al Sall" },
    { city: "Ras Al Khaima", geofence: "Al Seer" },
    { city: "Ras Al Khaima", geofence: "Al Soor" },
    { city: "Ras Al Khaima", geofence: "Al Turfah" },
    { city: "Ras Al Khaima", geofence: "Al Uraibi" },
    { city: "Ras Al Khaima", geofence: "Al Zahra" },
    { city: "Ras Al Khaima", geofence: "Burairaat" },
    { city: "Ras Al Khaima", geofence: "Dafan Al Khor" },
    { city: "Ras Al Khaima", geofence: "Dafan Al Nakheel" },
    { city: "Ras Al Khaima", geofence: "Dahan" },
    { city: "Ras Al Khaima", geofence: "Digdagga" },
    { city: "Ras Al Khaima", geofence: "Ghalilah" },
    { city: "Ras Al Khaima", geofence: "Hamrania" },
    { city: "Ras Al Khaima", geofence: "Khatt" },
    { city: "Ras Al Khaima", geofence: "Khazam" },
    { city: "Ras Al Khaima", geofence: "Liwa Badr" },
    { city: "Ras Al Khaima", geofence: "Mamoora" },
    { city: "Ras Al Khaima", geofence: "Mina Al arab" },
    { city: "Ras Al Khaima", geofence: "RAK City" },
    { city: "Ras Al Khaima", geofence: "Rams" },
    { city: "Ras Al Khaima", geofence: "Ras Al Selaab Area" },
    { city: "Ras Al Khaima", geofence: "Refa`ah" },
    { city: "Ras Al Khaima", geofence: "Seih Al Ghubb" },
    { city: "Ras Al Khaima", geofence: "Seih al Harf" },
    { city: "Ras Al Khaima", geofence: "Seih Al Hudaibah" },
    { city: "Ras Al Khaima", geofence: "Seih Al Qusaidat" },
    { city: "Ras Al Khaima", geofence: "Seih Al Uraibi" },
    { city: "Ras Al Khaima", geofence: "Seih Shihyar" },
    { city: "Ras Al Khaima", geofence: "Shamal Haqueel" },
    { city: "Ras Al Khaima", geofence: "Shamal Julphar" },
    { city: "Ras Al Khaima", geofence: "Shareesha" },
    { city: "Ras Al Khaima", geofence: "Sidroh" },
    { city: "Ras Al Khaima", geofence: "Suhaim Area" },
    { city: "Ras Al Khaima", geofence: "Wadi Ammar" }
  ],
  "Umm Al-Quwain": [
    { city: "Umm Al-Quwain", geofence: "Al Aahad" },
    { city: "Umm Al-Quwain", geofence: "Al Abrab" },
    { city: "Umm Al-Quwain", geofence: "Al Dar Al Baidah" },
    { city: "Umm Al-Quwain", geofence: "Al Haditha" },
    { city: "Umm Al-Quwain", geofence: "Al Hawiyah" },
    { city: "Umm Al-Quwain", geofence: "Al Humrah - A" },
    { city: "Umm Al-Quwain", geofence: "Al Humrah - B" },
    { city: "Umm Al-Quwain", geofence: "Al Humrah - C" },
    { city: "Umm Al-Quwain", geofence: "Al Humrah - D" },
    { city: "Umm Al-Quwain", geofence: "Al Khor" },
    { city: "Umm Al-Quwain", geofence: "Al Limgadhar" },
    { city: "Umm Al-Quwain", geofence: "Al Maidan" },
    { city: "Umm Al-Quwain", geofence: "Al Qarain" },
    { city: "Umm Al-Quwain", geofence: "Al Raas - A" },
    { city: "Umm Al-Quwain", geofence: "Al Raas - B" },
    { city: "Umm Al-Quwain", geofence: "Al Raas - C" },
    { city: "Umm Al-Quwain", geofence: "Al Rafaah (RAK)" },
    { city: "Umm Al-Quwain", geofence: "Al Ramlah" },
    { city: "Umm Al-Quwain", geofence: "Al Raudah" },
    { city: "Umm Al-Quwain", geofence: "Al Riqqah" },
    { city: "Umm Al-Quwain", geofence: "Al Salamah - A" },
    { city: "Umm Al-Quwain", geofence: "Al Salamah - B" },
    { city: "Umm Al-Quwain", geofence: "Al Salamah - C" },
    { city: "Umm Al-Quwain", geofence: "Defence Camp" },
    { city: "Umm Al-Quwain", geofence: "Emirates Modern Industrial" },
    { city: "Umm Al-Quwain", geofence: "Green Belt" },
    { city: "Umm Al-Quwain", geofence: "Industrial Area" },
    { city: "Umm Al-Quwain", geofence: "Khalifa City" },
    { city: "Umm Al-Quwain", geofence: "Umm Al Quwain Airport" },
    { city: "Umm Al-Quwain", geofence: "Umm Al Quwain Marina" }
  ]
};
