import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SideDrawer from "../FiltersSidedrawer/FiltersSideDrawer";
import TableColumns from "./TableColumns";
import { createCsvOrders, setNumOfOrders } from "../../actions/azdanActions";
import { logoutUser } from "../../actions/authActions";
import { clearAlert } from "../../actions/alertActions";
import orderSearching from "../../utils/orderSearching";
import Table from "./OrdersTable";
import "../Orders/Orders.css";
import "rsuite/dist/styles/rsuite-default.css";

class OrdersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      searchCategory: "all",
      view: "all",
      querySearch: false,
      filters: "",
      multiSearch: "",
      columnData: TableColumns,
    };

    this.onChangeSearchValue = this.onChangeSearchValue.bind(this);
    this.onChangeSearchCategory = this.onChangeSearchCategory.bind(this);
    this.navigate = this.navigate.bind(this);
    this.searchQuery = this.searchQuery.bind(this);
    this.changeQueryName = this.changeQueryName.bind(this);
    this.timeout = 0;

    this.categories = [];
    this.queryName = ``;
    this.handleMultiSearch = this.handleMultiSearch.bind(this);
  }

  handleMultiSearch(queryObj) {
    this.setState({
      multiSearch: queryObj,
    });
  }

  // using set timeout so onchange doesn't try to update page every keystroke
  onChangeSearchValue(e) {
    let target = e.target;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ searchValue: target.value });
    }, 500);
  }

  onChangeSearchCategory(e) {
    this.setState({
      searchCategory: e.target.value,
      filters: e.target.value,
      searchValue: "",
    });
  }

  searchQuery(e) {
    this.setState({
      querySearch: false,
    });

    let key = e.target.textContent;
    let data = localStorage.getItem("queries");

    if (key) {
      data = JSON.parse(data);
      if (data[key]) {
        this.setState({
          querySearch: true,
          filters: data[key],
          searchValue: "",
        });
      }
    }
  }

  changeQueryName(e) {
    this.queryName = e.target.value;
  }

  navigate(view) {
    this.setState({ view: view });
  }

  setHiddenColumns = (tableHiddenColumnArr) => {
    const tableColumnsArr = TableColumns;

    const filteredColumns = tableColumnsArr.filter(
      (elem) => !tableHiddenColumnArr.find((id) => elem.id === id)
    );
    this.setState({ columnData: filteredColumns });
  };

  componentDidMount() {
    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }

    this.setState({ columnData: TableColumns });
  }
  // @render
  render() {
    const { orders, submitInProgress } = this.props;
    const { searchValue, view, multiSearch } = this.state;

    let orderList = orders || [];
    let closedOrders = [];

    let activeOrders = orderList.filter((item) => {
      if (item["task_status"] !== "3" && item["task_status"] !== "5") {
        return true;
      }
      if (item["task_status"] !== "4") {
        closedOrders.push(item);
      }
      return false;
    });

    if (view === "activeOrders") {
      orderList = activeOrders;
    } else if (view === "closedOrders") {
      orderList = closedOrders;
    }

    if (multiSearch !== "") {
      orderList = orderSearching(orderList, multiSearch);
    } else if (searchValue !== "") {
      let searchObj = {};
      let searchKey = this.state.filters.slice();
      let searchVal = this.state.searchValue.slice();

      searchObj[searchKey] = searchVal;

      orderList = orderSearching(orderList, searchObj);
    }
    // to update count of filtered orders used in footer
    this.props.setNumOfOrders(orderList.length);

    let tableHeaders = [];
    this.state.columnData.forEach((column) => {
      tableHeaders.push({ label: column.label, key: column.id });
    });
    // Generate orders table body
    let tableBody;

    if (!orderList || !orderList.length) {
      tableBody = (
        <div className="text-center pt-2">
          <em className="text-muted">
            {submitInProgress ? "Checking for orders..." : "No orders found."}
          </em>
        </div>
      );
    } else {
      tableBody = (
        <Table
          orders={orderList}
          logoutUser={() => this.props.logoutUser()}
          clearAlert={() => this.props.clearAlert()}
          columnData={this.state.columnData}
        />
      );
    }

    // @return
    return (
      <div className="mx-2 mobile-margin">
        {/* ////////////////////// ORDER TABLE NAVBAR /////////////// */}
        <nav className="orders-nav navbar navbar-expand-lg navbar-light ">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#orderTableNavbar"
            aria-controls="orderTableNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <SideDrawer
            tableBody={tableBody}
            searchQuery={this.searchQuery}
            saveQuery={this.saveQuery}
            changeQueryName={this.changeQueryName}
            handleMultiSearch={this.handleMultiSearch}
            tableColumnsData={TableColumns}
            setColumnsHidden={this.setHiddenColumns}
            onChangeSearchValue={this.onChangeSearchValue}
            searchValue={searchValue}
            navigate={this.navigate}
            activeOrders={activeOrders.length}
            closedOrders={closedOrders.length}
            allOrders={orderList.length}
            currentOrders={orderList.slice()}
            tableHeaders={tableHeaders}
          />
        </nav>
      </div>
    );
  }
}

OrdersTable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  alert: state.alert,
  submitInProgress: state.order.submitInProgress,
});

export default connect(mapStateToProps, {
  createCsvOrders,
  logoutUser,
  clearAlert,
  setNumOfOrders,
})(OrdersTable);
