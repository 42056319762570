import { formatDate } from '../helpers/index';

import {
  SET_DATE_RANGE,
  GET_ORDERS,
  ORDER_CLICKED,
  ORDER_LOADING,
  SET_NUM_ORDERS,
  ADD_STATUS,
  DELETE_STATUS,
  USER_LOGOUT,
  SUBMIT_REQUEST,
  RECEIVE_REPLY,
  ADD_FILTER,
  ADD_SEARCH_QUERY,
  TOGGLE_TABLE_DENSE,
  GET_ORDER_UPLOADS,
  CLEAR_ORDER
} from '../actions/types';

const initialState = {
  searchQueries: [],
  savedFilters: [],
  orderList: null,
  order: {},
  uploads: [],
  tableDense: false,
  submitInProgress: false,
  loading: false,
  currentDateRange: [
    formatDate(Date.now()),
    formatDate(Date.now()),
    'Scheduled',
  ],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        currentDateRange: action.payload,
      };

    case ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SUBMIT_REQUEST:
      return {
        ...state,
        submitInProgress: true,
      };

    case RECEIVE_REPLY:
      return {
        ...state,
        submitInProgress: false,
      };

    case SET_NUM_ORDERS:
      return {
        ...state,
        numOrders: action.payload,
      };

    case GET_ORDERS:
      return {
        ...state,
        orderList: action.payload,
        loading: false,
      };

      case CLEAR_ORDER:
      return {
        ...state,
        order: {},
        loading: false,
      };

    case GET_ORDER_UPLOADS:
      return {
        ...state,
        uploads: action.payload,
        loading: false,
      };

    case TOGGLE_TABLE_DENSE:
      return {
        ...state,
        tableDense: !state.tableDense,
      };

    case ADD_STATUS:
      return {
        ...state,
        order: action.payload,
      };

    case DELETE_STATUS:
      return {
        ...state,
        order: action.payload,
      };

    case ORDER_CLICKED:
      return {
        ...state,
        order: action.payload,
      };

    case USER_LOGOUT:
      return initialState;

    case ADD_SEARCH_QUERY:
      return {
        ...state,
        searchQueries: [...state.searchQueries, action.payload],
      };

    case ADD_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters, action.payload],
      };

    default:
      return state;
  }
}
