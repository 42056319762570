import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { updateUserSettings } from '../../actions/usersActions';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '21px',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function FilterTableColumn(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(props.columnData);

  const setItemsLocalStorage = checkedItems => {
    props.add(checkedItems);

    localStorage.setItem('columnsHidden', JSON.stringify(checkedItems));
    props.updateUserSettings('columnsHidden', checkedItems);
  };

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setItemsLocalStorage(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
      setItemsLocalStorage(not(checked, items));
    } else {
      setChecked(union(checked, items));
      setItemsLocalStorage(union(checked, items));
    }
  };

  const getColumnCorrespondingText = id => {
    for (let i = 0; i < props.tableColumns.length; i++) {
      if (props.tableColumns[i].id === id) return props.tableColumns[i].label;
    }
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} Hidden`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map(value => {
          const label = getColumnCorrespondingText(value);

          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={value} primary={label} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList('Hide All', props.tableColumnsIds)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center"></Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { updateUserSettings })(
  FilterTableColumn
);
