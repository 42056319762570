import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { getAuthToken } from '../../actions/authActions';

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (getAuthToken() ? <Component {...rest} {...props} /> : <Redirect to={{ pathname: '/' }} />)}
    />
  );
};

export default AuthRoute;
