import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

import HideColumnsMenu from './HideColumnsMenu';
import '../Orders/Orders.css';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 21,
    zIndex: 10,
  },
}));

const HideColumnsBtn = props => {
  const classes = useStyles();
  const [columnsData, setColumns] = React.useState([]);
  const [status, showHideToggleColumn] = React.useState(false);

  const handleToggleColumn = () => {
    showHideToggleColumn(!status);
    if (status === false) {
      const columnsObject = localStorage.getItem('columnsHidden');

      const columnsObjectFormat = JSON.parse(columnsObject);
      if (columnsObjectFormat !== '' && columnsObjectFormat != null) {
        const ids = getTableColAllIds(columnsObjectFormat);

        setColumns(ids);
      }
    }
  };

  const getTableColId = columns => {
    let ids = [];
    columns.forEach(column => {
      ids.push(column.id);
    });
    return ids;
  };

  const getTableColAllIds = columns => {
    let ids = [];
    columns.forEach(column => {
      ids.push(column);
    });
    return ids;
  };

  return (
    <FormControl className="ml-2">
      <div className={classes.root}>
        <Tooltip title="Hide Columns" aria-label="Hide Columns">
          <IconButton
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            onClick={handleToggleColumn}
          >
            <VisibilityOffIcon />
          </IconButton>
        </Tooltip>
        {status ? (
          <div className={classes.dropdown}>
            <HideColumnsMenu
              add={data => props.setColumnsHidden(data)}
              tableColumns={props.tableColumnsData}
              tableColumnsIds={getTableColId(props.tableColumnsData)}
              columnData={columnsData}
            />
          </div>
        ) : null}
      </div>
    </FormControl>
  );
};

export default HideColumnsBtn;
