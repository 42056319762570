import {
  ImportContacts,
  LocalShipping,
  Store,
  SupervisorAccount,
} from '@material-ui/icons';
import React from 'react';

export default badgeType => {
  let badge = null;

  switch (badgeType) {
    case '1':
      badge = (
        <span
          style={{ background: '#EFF3C1', color: '#847C18' }}
          className="badge badge-primary align-middle"
        >
          Unassigned
        </span>
      );
      break;
    //
    case '2':
      badge = (
        <span
          style={{ background: '#D9ECC5', color: '#3E7120' }}
          className="badge  align-middle"
        >
          Assigned
        </span>
      );
      break;

    case '3':
      badge = (
        <span
          style={{ background: '#A4D7AB', color: '#1B6329' }}
          className="badge align-middle"
        >
          Completed
        </span>
      );
      break;

    case '4':
      badge = (
        <span
          style={{ background: '#F2A4A4', color: '#C12222' }}
          className="badge  align-middle"
        >
          Cancelled
        </span>
      );
      break;

    case '5':
      badge = (
        <span
          style={{ background: '#e53935', color: '#fff' }}
          className="badge  align-middle"
        >
          Failed
        </span>
      );
      break;

    case '6':
      badge = (
        <span
          style={{ background: '#FFF9BF', color: '#F9A521' }}
          className="badge align-middle"
        >
          In Transit
        </span>
      );
      break;

    case '7':
      badge = (
        <span
          style={{ background: '#B19BDA', color: '#fff' }}
          className="badge align-middle"
        >
          Picked Up
        </span>
      );
      break;

    case '9':
      badge = (
        <span
          style={{ background: '#CFC3E9', color: '#40249C' }}
          className="badge align-middle"
        >
          Arrived in DC
        </span>
      );
      break;

    case '10':
      badge = (
        <span
          style={{ background: '#E1BDE4', color: '#52188B' }}
          className="badge align-middle"
        >
          Out for Delivery
        </span>
      );
      break;

    case '15':
      badge = (
        <span
          style={{ background: '#F6B9D0', color: '#BA185E' }}
          className="badge align-middle"
        >
          Process for Return
        </span>
      );
      break;

    case '16':
      badge = (
        <span
          style={{ background: '#FFE0B0', color: '#EA6302' }}
          className="badge align-middle"
        >
          Returned to Shipper
        </span>
      );
      break;

    case '17':
      badge = (
        <span
          style={{ background: '#AAE8F0', color: '#006671' }}
          className="badge align-middle"
        >
          Hub Transfer
        </span>
      );
      break;

    case '19':
      badge = (
        <span
          style={{ background: '#AAE8F0', color: '#006671' }}
          className="badge align-middle"
        >
          Duplicate
        </span>
      );
      break;

    case '20':
      badge = (
        <span
          style={{ background: '#EA5830', color: '#FFF' }}
          className="badge align-middle"
        >
          Returned to DC
        </span>
      );
      break;

    case '21':
      badge = (
        <span
          style={{ background: '#c879ff', color: '#FFF' }}
          className="badge align-middle"
        >
          Not Recieved
        </span>
      );
      break;

    case '22':
      badge = (
        <span
          style={{ background: '#c879ff', color: '#FFF' }}
          className="badge align-middle"
        >
          Rescheduled
        </span>
      );
      break;

    case '23':
      badge = (
        <span
          style={{ background: '#c879ff', color: '#FFF' }}
          className="badge align-middle"
        >
         Reattempt
        </span>
      );
      break;

    case 'admin':
      badge = (
        <span className="badge badge-primary align-middle">
          <SupervisorAccount /> Admin
        </span>
      );
      break;

    case 'sales':
      badge = (
        <span className="badge badge-primary align-middle">
          <Store /> Sales
        </span>
      );
      break;

    case 'operations':
      badge = (
        <span className="badge badge-primary align-middle">
          <LocalShipping /> Operations
        </span>
      );
      break;

    case 'viewer':
      badge = (
        <span className="badge badge-primary align-middle">
          <ImportContacts /> Viewer
        </span>
      );
      break;

    default:
      badge = <span className="badge badge-primary align-middle">Unknown</span>;
      break;
  }

  return badge;
};
