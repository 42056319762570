export default [
  {
    id: 'nstaskid',
    disablePadding: false,
    label: 'Internal ID',
    width: 95,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_status',
    label: 'Status',
    numeric: false,
    disablePadding: false,
    width: 140,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_completeafterdate',
    label: 'Delivery Date',
    disablePadding: false,
    width: 130,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_completeaftertime',
    label: 'After Time',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_completebeforetime',
    label: 'Before Time',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_consigneename',
    label: 'Customer Name',
    disablePadding: false,
    width: 150,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_conscontactphone',
    label: 'Customer Mobile',
    disablePadding: false,
    width: 150,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_consaddline1',
    label: 'Address',
    disablePadding: false,
    width: 200,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_consdistrict',
    label: 'Area',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_conscity',
    label: 'City',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_conscountryname',
    label: 'Country',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_totalshipqty',
    label: 'No of Packages',
    disablePadding: false,
    width: 100,
    type: 'number',
    csvHeading: true,
    required: true,
  },
  {
    id: 'task_details',
    label: 'Package Details',
    disablePadding: false,
    width: 150,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_codamount',
    label: 'COD Amount',
    disablePadding: false,
    width: 150,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  // {
  //   id: 'task_paymentmethod',
  //   label: 'Mode of Payment',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: true,
  //   required: false,
  // },
  {
    id: 'task_notes',
    label: 'Delivery Notes',
    disablePadding: false,
    width: 150,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_customerorderno',
    label: 'Customer Order No',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },

  {
    id: 'task_bagsreturned',
    label: 'Bags Returned',
    disablePadding: false,
    width: 100,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_icepacks',
    label: 'Ice Packs Returned',
    disablePadding: false,
    width: 100,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_noofattempts',
    label: 'Number of Attempts',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_endtime',
    label: 'Delivery End Time',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },

  {
    id: 'task_smsnotification',
    label: 'SMS',
    disablePadding: false,
    width: 100,
    type: 'bool',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_deliverytype',
    label: 'Delivery Type',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_awb',
    label: 'AWB',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_volume',
    label: 'Volume',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_totaldecgrossw',
    label: 'Weight',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_customer',
    label: 'Customer',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },

  {
    id: 'task_complat',
    label: 'Completion Latitude',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_complong',
    label: 'Completion Longitude',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_failurereasonname',
    label: 'Failure Reason',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_reason',
    label: 'Reason',
    disablePadding: false,
    width: 100,
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_consrating',
    label: 'Consignee Rating',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_conscomment',
    label: 'Consignee Comment',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_conslatitude',
    label: 'Consignee Latitude',
    disablePadding: false,
    width: 95,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'Consignee Longitude',
    label: 'Consignee Longitude',
    disablePadding: false,
    width: 95,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_shippaymentmethod',
    label: 'Mode of Payment',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_collectedamt',
    label: 'Collected Amount',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_id',
    disablePadding: false,
    label: 'ID',
    width: 95,
    type: 'number',
    csvHeading: false,
    required: false,
  },
  {
    id: 'task_amazonsfname',
    label: 'Ship From Name',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_amazonshline1',
    label: 'Ship From Address',
    disablePadding: false,
    width: 100,
    type: 'text',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_amazonshcity',
    disablePadding: false,
    label: 'Ship From City',
    width: 95,
    type: 'number',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_amazonshcontactph',
    disablePadding: false,
    label: 'Ship From Mobile',
    width: 95,
    type: 'number',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_hangers',
    disablePadding: false,
    label: 'Hangers',
    width: 95,
    type: 'number',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_shipmentpackage',
    disablePadding: false,
    label: 'Shipment Packages',
    width: 95,
    type: 'number',
    csvHeading: true,
    required: false,
  },
  {
    id: 'task_type',
    disablePadding: false,
    label: 'Order Type',
    width: 95,
    type: 'number',
    csvHeading: true,
    required: false,
  },

  // {
  //   id: 'task_created',
  //   label: 'Created At',
  //   disablePadding: false,
  //   width: 130,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },

  // {
  //   id: 'task_starttime',
  //   label: 'Start Time',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },

  // {
  //   id: 'durationofdelivery',
  //   label: 'Delivery Duration',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },

  // {
  //   id: 'task_assignedat',
  //   label: 'Assigned At',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },
  // {
  //   id: 'task_lastmodified',
  //   label: 'Modified',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },
  // {
  //   id: 'task_arrivedat',
  //   label: 'Arrived At',
  //   disablePadding: false,
  //   width: 100,
  //   type: 'text',
  //   csvHeading: false,
  //   required: false,
  // },
];
