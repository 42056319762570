import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Tooltip, IconButton } from '@material-ui/core';
// core components
import GridItem from './components/GridItem.js';
import GridContainer from './components/GridContainer.js';
import Table from './components/Table.js';
import Card from './components/Card.js';
import CardHeader from './components/CardHeader.js';
import CardBody from './components/CardBody.js';

import { getOrders } from '../../actions/azdanActions';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker.js';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};
const useStyles = makeStyles(styles);

function TableList(props) {
  const classes = useStyles();
  const [macroChartData, setMacroChartData] = useState([]);
  const [microChartData, setMicroChartData] = useState([]);
  // const [macroGraphData, setMacroGraphData] = useState([]);
  // const [microGraphData, setMicroGraphData] = useState([]);
  const [ordersByCustomer, setOrdersByCustomer] = useState([]);
  const [ordersByDate, setOrdersByDate] = useState([]);
  const [dateSum, setDateSum] = useState([]);
  const [customerSum, setCustomerSum] = useState([]);

  useEffect(() => {
    if (props.orders === null) {
      props.getOrders();
    }
  }, []);

  useEffect(() => {
    const macroData = generateMacroChartData();
    const microData = generateMicroChartData();
    setMacroChartData(macroData);
    setMicroChartData(microData);
  }, [props]);

  // useEffect(() => {
  //   const microGraphData = generateMicroGraphData();
  //   setMicroGraphData(microGraphData);
  // }, [microChartData]);

  // useEffect(() => {
  //   const macroGraphData = generateMacroGraphData();
  //   setMacroGraphData(macroGraphData);
  // }, [macroChartData]);

  // const generateMacroGraphData = () => {
  //   const graphData = {
  //     labels: [],
  //     series: [[], [], []],
  //   };
  //   macroChartData.forEach(row => {
  //     graphData.labels.push(row[0]);
  //     graphData.series[0].push(row[1]);
  //     graphData.series[1].push(row[2]);
  //     graphData.series[2].push(row[3]);
  //   });
  //   return graphData;
  // };

  // const generateMicroGraphData = () => {
  //   const graphData = {
  //     labels: [],
  //     series: [[], [], []],
  //   };
  //   microChartData.forEach(row => {
  //     graphData.labels.push(row[0]);
  //     graphData.series[0].push(row[1]);
  //     graphData.series[1].push(row[2]);
  //     graphData.series[2].push(row[3]);
  //   });
  //   return graphData;
  // };

  const generateMacroChartData = () => {
    const data = {};
    const history = { type: 'task_consigneename' };
    let sum = [0, 0, 0];
    let orderList = props.orders;

    if (orderList === null) {
      orderList = [];
    }
    orderList.forEach(order => {
      const date = order.task_completeafterdate;
      if (!data[date]) {
        data[date] = [0, 0, 0];
        history[date] = [order];
      } else {
        history[date].push(order);
      }
      if (order.task_totalshipqty) {
        data[date][0] += Number(order.task_totalshipqty);
        sum[0] += Number(order.task_totalshipqty);
      }
      if (order.task_bagsreturned) {
        data[date][1] += Number(order.task_bagsreturned);
        sum[1] += Number(order.task_bagsreturned);
      }
      if (order.task_icepacks) {
        data[date][2] += Number(order.task_icepacks);
        sum[2] += Number(order.task_icepacks);
      }
    });
    setCustomerSum(sum);

    const keys = Object.keys(data);
    const vals = Object.values(data);
    const chartData = [];

    keys.forEach((date, i) => {
      chartData.push([date, ...vals[i]]);
    });

    setOrdersByCustomer(history);
    return chartData;
  };

  const generateMicroChartData = () => {
    const data = {};
    const history = { type: 'task_completeafterdate' };
    let sum = [0, 0, 0];
    let orderList = props.orders;
    if (orderList === null) {
      orderList = [];
    }
    orderList.forEach(order => {
      const customer = order.task_consigneename;
      if (!data[customer]) {
        data[customer] = [0, 0, 0];
        history[customer] = [order];
      } else {
        history[customer].push(order);
      }
      if (order.task_totalshipqty) {
        data[customer][0] += Number(order.task_totalshipqty);
        sum[0] += Number(order.task_totalshipqty);
      }
      if (order.task_bagsreturned) {
        data[customer][1] += Number(order.task_bagsreturned);
        sum[1] += Number(order.task_bagsreturned);
      }
      if (order.task_icepacks) {
        data[customer][2] += Number(order.task_icepacks);
        sum[2] += Number(order.task_icepacks);
      }
    });
    setDateSum(sum);

    const keys = Object.keys(data);
    const vals = Object.values(data);
    const chartData = [];

    keys.forEach((date, i) => {
      chartData.push([date, ...vals[i]]);
    });

    setOrdersByDate(history);
    return chartData;
  };

  const csvData = [
    ['Date', 'No of Packages', 'Bags Returned', 'Ice Packs Returned'],
    ...microChartData,
    ['Date', 'No of Packages', 'Bags Returned', 'Ice Packs Returned'],
    ...macroChartData,
  ];

  return (
    <>
      <div
        style={{
          marginLeft: '-10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CSVLink
          data={csvData}
          filename={'tcorp-overview-report.csv'}
          className="ml-2"
        >
          <Tooltip title="Download CSV" aria-label="Download CSV">
            <IconButton
              varient="contained"
              color="primary"
              size="medium"
              aria-label="export-csv"
              type="button"
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
        <CustomDatePicker />
      </div>
      <GridContainer style={{ height: '87vh', overflow: 'scroll' }}>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Assets by Date</h4>
              <p className={classes.cardCategoryWhite}>
                Here you can view what resources you have had delivered and
                returned
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  'Date',
                  'No of Packages',
                  'Bags Returned',
                  'Ice Packs Returned',
                ]}
                tableData={macroChartData}
                collapseData={ordersByCustomer}
                footerData={customerSum}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Assets by Customer</h4>
              <p className={classes.cardCategoryWhite}>
                Here you can view what resources you have had delivered and
                returned
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  'Name',
                  'No of Packages',
                  'Bags Returned',
                  'Ice Packs Returned',
                ]}
                tableData={microChartData}
                collapseData={ordersByDate}
                footerData={dateSum}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    orders: state.order.orderList,
  };
};

export default connect(mapStateToProps, { getOrders })(TableList);
