const holidays = [
  {
    title: "New Year's Day",
    comments: 'Regular holiday',
    month: '01',
    day: '01',
  },
  {
    title: 'Christmas Eve',
    comments: 'Special non-working day',
    month: '12',
    day: '24',
  },
  {
    title: 'Christmas Day',
    comments: 'Regular holiday',
    month: '12',
    day: '25',
  },
  {
    title: "New Year's Eve",
    comments: 'Special non-working day',
    month: '12',
    day: '31',
  },
];

const now = new Date();

export default holidays.map((holiday, index) => {
  return {
    id: index,
    title: holiday.title,
    allDay: true,
    start: new Date(`${now.getFullYear()}-${holiday.month}-${holiday.day}`),
    end: new Date(`${now.getFullYear()}-${holiday.month}-${holiday.day}`),
    type: 'Holiday',
  };
});
