// alert

import { RECEIVE_CSV_REPLY, SUBMIT_CSV_REQUEST } from '../actions/types';

const initialState = {
  uploading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CSV_REQUEST:
      return {
        ...state,
        uploading: true,
      };

    case RECEIVE_CSV_REPLY:
      return {
        ...state,
        uploading: false,
      };

    default:
      return state;
  }
}
