import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Rating from '@material-ui/lab/Rating';

const ratings = ['1', '2', '3', '4', '5'];

const RatingFilter = props => {
  const clearChecked = () => {
    props.handleRating('');
  };

  const getRatingStars = status => {
    switch (status) {
      case '1':
        return <Rating name="read-only" value={1} readOnly />;
      case '2':
        return <Rating name="read-only" value={2} readOnly />;
      case '3':
        return <Rating name="read-only" value={3} readOnly />;
      case '4':
        return <Rating name="read-only" value={4} readOnly />;
      case '5':
        return <Rating name="read-only" value={5} readOnly />;
      default:
        return console.log('Now what now?');
    }
  };

  return (
    <>
      <Autocomplete
        multiple
        key={props.clear}
        onInputChange={clearChecked}
        options={ratings}
        disableCloseOnSelect
        size="small"
        getOptionLabel={rating => rating}
        filterSelectedOptions={true}
        onChange={(_, newVal) => props.handleRating(newVal)}
        renderOption={rating => (
          <div key={rating} style={{ width: '100%' }}>
            <span>{getRatingStars(rating)}</span>
          </div>
        )}
        limitTags={3}
        renderInput={params => <TextField {...params} label="Rating" />}
      />
    </>
  );
};
export default RatingFilter;
