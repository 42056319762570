import React from 'react';
import { connect } from 'react-redux';

import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { clearSuccess } from '../../actions/successActions';
import SuccessMsg from './SuccessMsg';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    left: '26%',
    position: 'absolute',
    zIndex: '99999',
    top: '8%',
    maxHeight: '90vh',
    overflow: 'auto',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  btn: {
    backgroundColor: '#0d8cd1',
    color: '#fff',
    margin: '-7px 7px 0 0',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
  },
  btnPrimary: {
    backgroundColor: '#0d8cd1',
    border: 'none',
    fontWeight: '300 !important',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#303F9F',
    },
  },
}));

const CustomizedSnackbars = props => {
  const classes = useStyles();
  const [, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    props.clearSuccess();
    if (reason === 'clickaway') {
      return;
    }
    window.location.reload();
    setOpen(false);
  };

  const { success } = props;
  // makes the success message display singular or plural w/ number of orders uploaded
  let msg =
    success.show && success.data.order.createstatus.ok > 1
      ? success.data.order.createstatus.ok + ' orders'
      : 'an order';

  let successMessage = '';
  if (success.show) {
    successMessage = `Success! You have ${msg} on the way!`;
  } else if (success.editShow) {
    successMessage = 'Your changes have been successfully saved!';
  }

  return (
    <div className={classes.root} data-test="success">
      <Collapse in={success.show || success.editShow}>
        <Alert
          severity="success"
          icon={false}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <SuccessMsg msg={successMessage} />
        </Alert>
      </Collapse>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    success: state.success,
  };
};

export default connect(mapStateToProps, { clearSuccess })(CustomizedSnackbars);
