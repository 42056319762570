import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { clearAlert } from '../../actions/alertActions';

class AlertBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      success: false,
      message: '',
    };
  }

  componentDidMount() {
    this.setState({
      show: false,
      success: false,
      message: '',
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.alert !== prevProps.alert) {
      let { show, success, message } = this.props.alert;
      if (!Array.isArray(message)) {
        success = false;
        const keys = Object.keys(message);
        message = keys.map(key => message[key]);
      }
      this.setState({ show: show, success: success, message: message });
    }
  }

  render() {
    let { show, success, message } = this.state;
    message = [...message];
    let alert = null;

    if (show === true) {
      alert = (
        <div
          className={classnames(
            'tcorp-print-hide alert alert-dismissible fade show',
            {
              'alert-success': success === true,
              'alert-danger': success === false,
            }
          )}
          role="alert"
        >
          {message.map(msg => msg)}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={this.props.clearAlert}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }

    return <div>{alert}</div>;
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
});

export default connect(mapStateToProps, { clearAlert })(AlertBox);
