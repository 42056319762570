import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import errorReducer from './errorReducer';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import registerReducer from './registerReducer';
import successReducer from './successReducer';
import orderReducer from './orderReducer';
import alertReducer from './alertReducer';
import activityReducer from './activityReducer';
import modalReducer from './modalReducer';
import csvReducer from './csvReducer';

const orderPersistConfig = {
  key: 'order',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['orderList'],
};

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  users: usersReducer,
  register: registerReducer,
  success: successReducer,
  order: persistReducer(orderPersistConfig, orderReducer),
  alert: alertReducer,
  activity: activityReducer,
  modal: modalReducer,
  csv: csvReducer,
});
