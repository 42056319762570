import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
  },
  container: {
    display: 'flex',
  },
  type: {
    lineHeight: '1.435',
    paddingLeft: '12px',
  },
  svg: {
    width: 50,
    height: 50,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

export default function SuccessMsg(props) {
  const classes = useStyles();
  const [checked] = React.useState(true);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grow
          in={checked}
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 1500 } : {})}
        >
          <svg className={classes.svg}>
            <CheckCircleOutlineIcon />
          </svg>
        </Grow>
        {/* Conditionally applies the timeout prop to change the entry speed. */}
        <Grow
          in={checked}
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 2000 } : {})}
        >
          <Typography variant="h4" component="h2" className={classes.type}>
            {props.msg}
          </Typography>
        </Grow>
      </div>
    </div>
  );
}
