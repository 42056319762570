import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';

import DateRangeButtons from './DateRangeButtons';
import { getOrders } from '../../actions/azdanActions';

const CustomDatePicker = props => {
  const [searchType, setSearchType] = useState('Scheduled');

  const handleSearchType = () => {
    setSearchType(searchType === 'Scheduled' ? 'Completed' : 'Scheduled');
  };

  const handleDateRange = range => {
    let from = range[0] ? moment(range[0]).format('YYYY-MM-DD') : null;
    let to = range[1] ? moment(range[1]).format('YYYY-MM-DD') : null;
    props.getOrders(from, to, searchType);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '7px 0' }}>
      <DateRangePicker
        className="mx-2"
        appearance="default"
        onClean={() => window.location.reload()}
        style={{
          width: '230px',
          marginTop: '1px',
        }}
        placeholder={`${props.order.currentDateRange[0]} ~ ${props.order.currentDateRange[1]}`}
        id="orders_range"
        onChange={range => handleDateRange(range)}
        ranges={[
          {
            label: 'Yesterday',
            value: [
              moment(Date.now()).add(-1, 'days'),
              moment(Date.now()).add(-1, 'days'),
            ],
          },
          {
            label: 'Today',
            value: [new Date(), new Date()],
          },
          {
            label: 'Tomorrow',
            value: [
              moment(Date.now()).add(1, 'days'),
              moment(Date.now()).add(1, 'days'),
            ],
          },
          {
            label: 'Next 7 days',
            value: [moment(Date.now()), moment(Date.now()).add(7, 'days')],
          },
        ]}
      />
      <DateRangeButtons
        handleSearchType={handleSearchType}
        searchType={searchType}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    order: state.order,
  };
};

export default connect(mapStateToProps, { getOrders })(CustomDatePicker);
