import React from 'react';

import ActivityItem from './ActivityItem';

const ActivityList = ({ orders }) => {
  if (orders.length === 0) {
    return (
      <div
        className={`list-group-item flex-column align-items-start mb-1 empty-activity`}
      >
        No orders to display at this time.
      </div>
    );
  }
  return orders.map(order => <ActivityItem key={order._id} order={order} />);
};

export default ActivityList;
