export const GET_ERRORS = 'GET_ERRORS';
export const GET_CSV_ERRORS = 'GET_CSV_ERRORS';
export const GET_CSV_HEADER_ERRORS = 'GET_CSV_HEADER_ERRORS';
export const GET_ORDER_ERRORS = 'GET_ORDER_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_LOGIN_ERRORS = 'GET_LOGIN_ERRORS';

export const SUCCESS_CREATE = 'SUCCESS_CREATE';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
export const SUBMIT_REQUEST = 'SUBMIT_REQUEST';
export const RECEIVE_REPLY = 'RECEIVE_REPLY';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_SUCCESS_BAR = 'SHOW_SUCCESS_BAR';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const CLEAR_REGISTER = 'CLEAR_REGISTER';
export const SUBMIT_USERS_REQUEST = 'SUBMIT_USERS_REQUEST';
export const RECEIVE_USERS_REPLY = 'RECEIVE_USERS_REPLY';
export const SUBMIT_USERS_SUCCESS = 'SUBMIT_USERS_SUCCESS';
export const RESET_USERS_SUCCESS = 'RESET_USERS_SUCCESS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_ORDERS = 'GET_ORDERS';
export const SET_NUM_ORDERS = 'SET_NUM_ORDERS';
export const EDIT_ORDER = 'EDIT_ORDER';
export const ORDER_CLICKED = 'ORDER_CLICKED';
export const ADD_FILTER = 'ADD_FILTER';
export const ADD_SEARCH_QUERY = 'ADD_SEARCH_QUERY';
export const ORDER_LOADING = 'ORDER_LOADING';
export const SET_DATE_RANGE = 'SET_DATE_RANGE';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_STATUS = 'ADD_STATUS';
export const SUBMIT_COMPLETE_ORDER = 'SUBMIT_COMPLETE_ORDER';
export const SUBMIT_COMPLETE = 'SUBMIT_COMPLETE';
export const TOGGLE_TABLE_DENSE = 'TOGGLE_TABLE_DENSE';
export const GET_ORDER_UPLOADS = 'GET_ORDER_UPLOADS';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const DELETE_STATUS = 'DELETE_STATUS';
export const DELETE_ORDER = 'DELETE_ORDER';

export const SET_ACTIVITY_LOADING = 'SET_ACTIVITY_LOADING';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';

export const SUBMIT_CSV_REQUEST = 'SUBMIT_CSV_REQUEST';
export const RECEIVE_CSV_REPLY = 'RECEIVE_CSV_REPLY';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SHOW_CREATE_ORDER_MODAL = 'SHOW_CREATE_ORDER_MODAL';
export const SHOW_CSVMAP_MODAL = 'SHOW_CSVMAP_MODAL';
export const SHOW_EDIT_ORDER_MODAL = 'SHOW_EDIT_ORDER_MODAL';
