import React from 'react';

import { TextField, InputAdornment } from '@material-ui/core';
import '../Orders/Orders.css';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
  root: {
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: '4px',
    background: 'rgb(255, 255, 255)',
    boxShadow: '1px 2px 5px 0px rgba(221, 221, 221, 1)',
    padding: '2px 11px',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});

const OrderSearchBar = props => {
  const classes = useStyles();
  return (
    <TextField
      autoFocus
      InputProps={{
        classes,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon style={{ color: '#8e8e93' }} />
          </InputAdornment>
        ),
      }}
      type="text"
      placeholder="Search Orders..."
      name="searchValue"
      id={props.id}
      onChange={props.onChangeSearchValue}
    />
  );
};

export default OrderSearchBar;
