import axios from 'axios';

import {
  GET_ORDERS,
  GET_ERRORS,
  ORDER_CLICKED,
  CLEAR_ERRORS,
  ADD_ORDER,
  ADD_STATUS,
  DELETE_STATUS,
  SHOW_ALERT,
  SUBMIT_REQUEST,
  RECEIVE_REPLY,
  SUBMIT_COMPLETE_ORDER,
  SUBMIT_COMPLETE,
} from './types';

// ////////////////////////////////////
// @route  GET api/orders/
// @discrption  Get orders
// @access  Private
export const getOrders = () => dispatch => {
  axios.get('/api/orders/').then(res =>
    dispatch({
      type: GET_ORDERS,
      payload: res.data,
    })
  );
};

// ////////////////////////////////////
// @route  POST api/orders/
// @discrption  Create new order
// @access  Private
export const createOrders = orderData => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .post('/api/orders/', orderData)
    .then(res => {
      dispatch({
        type: ADD_ORDER,
        payload: res.data,
      });

      dispatch({
        type: SHOW_ALERT,
        payload: `Order id: ${res.data._id} was successfully created.`,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// ////////////////////////
// Open order view
export const openOrderView = order => dispatch => {
  dispatch({
    type: ORDER_CLICKED,
    payload: order,
  });
};

// ////////////////////////////////////
// @route  PUT api/orders/edit
// @discrption  Edit order
// @access  Private
export const editOrder = order => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .put('/api/orders/edit', order)
    .then(res => {
      dispatch({
        type: ORDER_CLICKED,
        payload: res.data,
      });

      dispatch({
        type: SHOW_ALERT,
        payload: `Order id: ${res.data._id} was successfully changed.`,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });

      dispatch(getOrders());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// //////////////////////////////////////
// @route  PUT api/orders/complete/
// @discrption  Submit complete order
// @access  Private
export const submitCompleteOrder = data => dispatch => {
  dispatch({ type: SUBMIT_REQUEST });

  axios.put('/api/orders/complete', data).then(res => {
    dispatch({
      type: ORDER_CLICKED,
      payload: res.data,
    });

    dispatch({
      type: SUBMIT_COMPLETE,
    });

    dispatch({
      type: SHOW_ALERT,
      payload: `Order id: ${res.data._id} was successfully marked as complete.`,
    });

    dispatch({
      type: RECEIVE_REPLY,
    });

    dispatch(getOrders());
  });
};

// ////////////////////////////////
// @route  DELETE api/orders/:id
// @discrption  Archive order
// @access  Private
export const deleteOrder = order => dispatch => {
  if (window.confirm('Are you sure? This order will be archived.')) {
    dispatch({ type: SUBMIT_REQUEST });

    axios
      .post(`/api/orders/${order._id}`)
      .then(res => {
        dispatch({
          type: RECEIVE_REPLY,
        });

        dispatch({
          type: SHOW_ALERT,
          payload: `Order was successfully archived.`,
        });

        // $('#OrderView .close').click();

        dispatch(getOrders());
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });
      });
  }
};

/// OPERATIONS ////

// ////////////////////////////////////
// @route  PUT api/operations/:id
// @discrption  Changes status of order
// @access  Private
// @payload { isFinished, remarks, stage }
export const submitComplete = (order, statusData) => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .put(`/api/operations/${order._id}`, statusData)
    .then(res => {
      dispatch({
        type: ORDER_CLICKED,
        payload: res.data,
      });

      if (statusData.stage === 'complete') {
        dispatch({
          type: SUBMIT_COMPLETE_ORDER,
        });
      }

      dispatch({
        type: RECEIVE_REPLY,
      });

      dispatch(getOrders());
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// ///////////////////////////////////////
// @route  PUT api/operations/:id/status
// @discrption  Change order status and add new Activity
// @access  Private
// @payload { comment, dateInput, stage }
export const addStatus = (order, statusData) => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .put(`/api/operations/${order._id}/status`, statusData)
    .then(res => {
      dispatch({
        type: ADD_STATUS,
        payload: res.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });

      dispatch(getOrders());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// ////////////////////////////////////////////////////
// @route  DELETE api/operations/:id/status/:status_id
// @discrption  Delete operations status
// @access  Private
// @payload { stage }
export const deleteStatus = (order, statusData, stage) => dispatch => {
  if (window.confirm('Are you sure?')) {
    dispatch({ type: SUBMIT_REQUEST });

    axios
      .delete(`/api/operations/${order._id}/status/${statusData}`, stage)
      .then(res => {
        dispatch({
          type: DELETE_STATUS,
          payload: res.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });

        dispatch(getOrders());
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });
      });
  }
};

////////////// ADD API DATA /////////////
// Delete complete
export const deleteComplete = (order, statusData) => dispatch => {
  if (window.confirm('Are you sure?')) {
    dispatch(clearErrors());

    dispatch({ type: SUBMIT_REQUEST });

    axios
      .delete(`/api/operations/${order._id}`, statusData)
      .then(res => {
        dispatch({
          type: ORDER_CLICKED,
          payload: res.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });

        dispatch(getOrders());
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });
      });
  }
};

// ///////////////////
// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};
