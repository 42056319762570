import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOGOUT,
  CLEAR_ERRORS,
  USER_LOGIN_LOADING,
} from './types';

// ////////////////////////////////////
// @route  POST api/users/login
// @discrption  Login User and return JWT Token
// @access  Public
export const loginUser = userData => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: USER_LOGIN_LOADING, payload: true });

  axios
    .post('/api/users/login', userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

      dispatch({ type: USER_LOGIN_LOADING, payload: false });
    });
};

// ////////////////////
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// //////////////////
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('queries');
  localStorage.removeItem('savedCsvHeaders');
  localStorage.removeItem('columnsHidden');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAutenticated to false
  dispatch(setCurrentUser({}));

  window.location.href = '/';

  // dispatch({ type: CLEAR_USERS, payload: null });
  dispatch({ type: USER_LOGOUT });
};

// /////////////////////////
// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const getAuthToken = () => {
  let token = localStorage.getItem('jwtToken') || null;

  token =
    token && new window.Date(jwt_decode(token).exp * 1000) > new window.Date()
      ? token
      : null;
  return token;
};
