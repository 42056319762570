import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
// core components
import GridItem from './components/GridItem.js';
import GridContainer from './components/GridContainer.js';
import Button from './components/Button.js';
import Card from './components/Card.js';
import CardHeader from './components/CardHeader.js';
import CardBody from './components/CardBody.js';
import CardFooter from './components/CardFooter.js';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  gridContainer: {
    alignItems: 'flex-end',
  },
};

const useStyles = makeStyles(styles);

const UserProfile = props => {
  const classes = useStyles();
  const [emailFreq, setEmailFreq] = React.useState('');

  const handleChange = event => {
    setEmailFreq(event.target.value);
  };

  return (
    <div className="mt-4">
      <GridContainer className={classes.gridContainer}>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Email Reports</h4>
              <p className={classes.cardCategoryWhite}>
                If you would like reports emailed to you, please enter the email
                addresses and how often you would like emails sent.
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Email Addresses
                    </InputLabel>
                    <Input
                      labelText="Email Addresses"
                      id="email-reports"
                      className={classes.input}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                    <FormHelperText>
                      <em>Separate multple email addresses with commas</em>
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Email Frequency
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={emailFreq}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'Daily'}>Daily</MenuItem>
                      <MenuItem value={'Weekly'}>Weekly</MenuItem>
                      <MenuItem value={'Monthly'}>Monthly</MenuItem>
                    </Select>
                    <FormHelperText>
                      <em>Daily, Monthy, Weekly or none</em>
                    </FormHelperText>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary">Save Email Settings</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(UserProfile);
