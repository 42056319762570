import React from 'react';
import spinner from './spinner-small.gif';
import '../../App.css';

export default () => {
  return (
    <div className="d-flex align-items-center sm-spinner">
      <img
        src={spinner}
        alt="Loading spinner"
        style={{
          margin: 'auto auto auto 20px',
          display: 'block',
          width: '45px',
        }}
      />{' '}
      <span className="blink-one blink-two">Working...</span>
    </div>
  );
};
