import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export default function ToggleButtonSizes(props) {
  const [alignment, setAlignment] = React.useState('all');

  const handleChange = (event, newAlignment) => {
    if (newAlignment === 'inactive') {
      props.navigate('closedOrders');
    } else if (newAlignment === 'active') {
      props.navigate('activeOrders');
    } else {
      props.navigate('allOrders');
    }
    setAlignment(newAlignment);
  };

  return (
    <Grid item className=" active-order-tabs mr-4">
      <ToggleButtonGroup
        size="small"
        value={alignment}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="all" style={{ width: '80px', height: '36px' }}>
          <Tooltip title={`All Orders: ${props.allOrders}`} aria-label="all">
            <span>All</span>
          </Tooltip>
        </ToggleButton>

        <ToggleButton
          value="inactive"
          style={{ width: '80px', height: '36px' }}
        >
          <Tooltip
            title={`Complete Orders: ${props.closedOrders}`}
            aria-label="inactive"
          >
            <span>Complete</span>
          </Tooltip>
        </ToggleButton>

        <ToggleButton value="active" style={{ width: '80px', height: '36px' }}>
          <Tooltip
            title={`Pending Orders: ${props.activeOrders}`}
            aria-label="active"
          >
            <span>Pending</span>
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}
