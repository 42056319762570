import React from 'react';
import spinner from './spinner-small.gif';
import '../../App.css';

export default () => {
  return (
    <div className="d-flex align-items-center sm-spinner">
      <img
        src={spinner}
        alt="Loading spinner"
        style={{ margin: 'auto', display: 'block', width: '45px' }}
      />
    </div>
  );
};
