import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginTop: '-17px',
    width: '150px',
  },
};

const useStyles = makeStyles(styles);

const CityFilter = props => {
  const cities = [...new Set(props.cities)];
  const classes = useStyles();
  const clearChecked = () => {
    props.handleCity('');
  };

  return (
    <>
      <Autocomplete
        multiple
        key={props.clear}
        onInputChange={clearChecked}
        options={cities}
        disableCloseOnSelect
        size="small"
        getOptionLabel={cities => cities}
        filterSelectedOptions={true}
        onChange={(_, newVal) => props.handleCity(newVal)}
        renderOption={cities => (
          <div key={cities} style={{ width: '100%' }}>
            <span>{cities}</span>
          </div>
        )}
        limitTags={3}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: classes.root }}
            label="City"
          />
        )}
      />
    </>
  );
};
export default CityFilter;
