// activity

import {
  SET_ACTIVITY_LOADING,
  GET_ACTIVITIES,
  USER_LOGOUT,
} from '../actions/types';

const initialState = {
  activities: { activities: {}, loading: true },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY_LOADING:
      return {
        ...state,
        activities: { loading: true },
      };

    case GET_ACTIVITIES:
      return {
        ...state,
        activities: { activities: action.payload, loading: false },
      };

    case USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
