// success

import { ADD_ORDER, EDIT_ORDER, CLEAR_SUCCESS } from '../actions/types';

const initialState = {
  show: false,
  editShow: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        show: true,
        data: action.payload,
      };

    case EDIT_ORDER:
      return {
        ...state,
        editShow: true,
      };

    case CLEAR_SUCCESS:
      return initialState;

    default:
      return state;
  }
}
