/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import {
  Dashboard,
  Assessment,
  ListAlt,
  MonetizationOn,
  Map,
  Person,
} from '@material-ui/icons';

// core components/views for Admin layout
import DashboardPage from './Dashboard.js';
import TableList from './InventoryReport.js';

const dashboardRoutes = [
  {
    path: '/reports/dashboard',
    name: 'Dashboard',
    icon: Assessment,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/reports/inventoryReport',
    name: 'Inventory Mgmt Report',
    icon: Dashboard,
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/reports/orderReport',
    name: 'Order Breakdown',
    icon: ListAlt,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/reports/codReport',
    name: 'COD Report',
    icon: MonetizationOn,
    component: TableList,
  },
  {
    path: '/reports/overviewReport',
    name: 'Overview',
    icon: Map,
    component: TableList,
  },
  {
    path: '/reports/emailReports',
    name: 'Email Reports',
    icon: Person,
    // component: UserProfile,
    layout: '/admin',
  },
];

export default dashboardRoutes;
