import React from 'react';
import { FormHelperText, ButtonBase, Tooltip } from '@material-ui/core';

export default function DateRangeButtons(props) {
  return (
    <Tooltip title={`Get Orders by ${props.searchType} Date`}>
      <ButtonBase
        focusRipple
        onClick={props.handleSearchType}
        style={{ display: 'block', marginLeft: '3px' }}
      >
        <FormHelperText style={{ lineHeight: '1.2' }}>
          Search by:{' '}
        </FormHelperText>
        <span style={{ color: '#0d8cd1' }}>{props.searchType} Date</span>
      </ButtonBase>
    </Tooltip>
  );
}
