import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from 'moment';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { openEditModal } from '../../actions/azdanActions';
import { logoutUser } from '../../actions/authActions';
import holidays from './holidays';
import generateEvents from './generateEvents';
import Spinner from '../common/Spinner';
import HolidayPopUp from './HolidayPopup';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

BigCalendar.momentLocalizer(moment);

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOrder: true,
      showHolidays: true,
      isBig: false,
    };

    this.toggleCheck = this.toggleCheck.bind(this);
  }

  componentDidMount() {
    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }
  }

  toggleCheck(e) {
    this.setState({ [e.target.name]: !this.state[e.target.name] }, () => {});
  }

  render() {
    const { order, openEditModal } = this.props;
    const { orderList } = this.props.order;
    const { showOrder, showHolidays } = this.state;

    let events = [];

    let orderEvents = [];

    if (showHolidays === true) {
      events = events.concat(holidays);
    }

    if (orderList !== null) {
      orderEvents = generateEvents(orderList);

      if (showOrder === true) {
        events = events.concat(orderEvents);
      }
    }

    const EventWrapper = () => ({ event }) => {
      if (event.type === 'Holiday') {
        return (
          <div>
            <button
              className="btn btn-sm btn-secondary w-100 d-block text-left text-nowrap text-truncate"
              data-toggle="modal"
              data-target="#holidayPopup"
            >
              {event.title}
            </button>

            <HolidayPopUp eventTitle={event.title} eventDate={event.start} />
          </div>
        );
      } else {
        return (
          <button
            className={`btn btn-sm  w-100 d-block text-white text-left ${event.status}`}
            data-toggle="modal"
            data-target="#OrderView"
            onClick={() => {
              openEditModal(event.order);
            }}
            title={`${event.title} ${event.task_consigneename} (${event.task_status} - ${event.operationsStatus}) — ${event.client}`}
          >
            <strong>{event.title}</strong>
          </button>
        );
      }
    };

    // Define custom toolbar
    class CustomToolbar extends Toolbar {
      navigate = action => {
        this.props.onNavigate(action);
      };

      view = view => {
        this.props.onView(view);
      };

      render() {
        return (
          <nav className="orders-nav navbar navbar-expand-sm navbar-light mb-2 mt-2">
            <div style={{ width: '12rem' }}>
              <span className="navbar-brand">{this.props.label}</span>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#calendarNavbar"
              aria-controls="calendarNavbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            {/* @navbar */}
            <div className="collapse navbar-collapse" id="calendarNavbar">
              <div
                className="btn-group shadow-sm"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.navigate('PREV')}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.navigate('TODAY')}
                >
                  Today
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => this.navigate('NEXT')}
                >
                  Next
                </button>
              </div>
            </div>
          </nav>
        );
      }
    }

    // Custom components
    let components = {
      event: Event,
      toolbar: CustomToolbar,
      eventWrapper: EventWrapper({ openEditModal }),
    };

    // MAIN CONTENT
    let content;

    if (order.orderList === null || order.loading) {
      content = <Spinner />;
    } else {
      content = (
        <div
          style={{
            height: this.state.isBig ? '121rem' : '70rem',
          }}
          className="Calendar mx-3 mobile-margin pb-5"
        >
          <BigCalendar
            events={events}
            showMultiDayTimes
            views={['month']}
            popup
            components={components}
          />

          <div className="text-center mt-2">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="showOrder"
                name="showOrder"
                checked={this.state.showOrder}
                onChange={this.toggleCheck}
              />
              <label className="form-check-label" htmlFor="showOrder">
                Show Orders
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="showHolidays"
                id="showHolidays"
                checked={this.state.showHolidays}
                onChange={this.toggleCheck}
              />
              <label className="form-check-label" htmlFor="showHolidays">
                Show Holidays
              </label>
            </div>

            <div
              className="btn-group shadow-sm "
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className={classnames('btn btn-outline-primary ', {
                  active: this.state.isBig === false,
                })}
                onClick={() => {
                  this.setState({ isBig: false });
                  this.forceUpdate();
                }}
              >
                Small
              </button>

              <button
                type="button"
                className={classnames('btn btn-outline-primary ', {
                  active: this.state.isBig === true,
                })}
                onClick={() => {
                  this.setState({ isBig: true });
                  this.forceUpdate();
                }}
              >
                Big
              </button>
            </div>
          </div>
        </div>
      );
    }

    return <div className="fade-in mb-5 calendar-container">{content}</div>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  order: state.order,
});

export default connect(mapStateToProps, { openEditModal, logoutUser })(
  Calendar
);
