import React from 'react';
import spinner from './spinnerLg.gif';

export default () => {
  return (
    <div>
      <img
        src={spinner}
        alt="Loading spinner"
        style={{ margin: 'auto', display: 'block', width: '400px' }}
      />
    </div>
  );
};
