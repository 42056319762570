import {
  SHOW_CSVMAP_MODAL,
  SHOW_CREATE_ORDER_MODAL,
  SHOW_EDIT_ORDER_MODAL,
  CLOSE_MODAL,
} from '../actions/types';

const initialState = {
  customerHeaders: [],
  csvShow: '',
  createShow: '',
  editShow: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_CSVMAP_MODAL:
      return {
        ...state,
        csvShow: 'show',
        customerHeaders: action.payload,
        orderData: action.orderData,
      };

    case SHOW_CREATE_ORDER_MODAL:
      return {
        ...state,
        createShow: 'show',
      };

    case SHOW_EDIT_ORDER_MODAL:
      return {
        ...state,
        editShow: 'show',
      };

    case CLOSE_MODAL:
      return {
        ...state,
        csvShow: '',
        createShow: '',
        editShow: '',
      };

    default:
      return state;
  }
}
