import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const status = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '9',
  '10',
  '15',
  '16',
  '17',
  '19',
  '20',
  '21',
  '22',
  '23'
];

const StatusFilter = props => {
  const clearChecked = () => {
    props.handleStatus('');
  };

  const getStatusName = status => {
    switch (status) {
      case '1':
        return 'Unassigned';
      case '2':
        return 'Assigned';
      case '3':
        return 'Complete';
      case '4':
        return 'Cancelled';
      case '5':
        return 'Failed';
      case '6':
        return 'In Transit';
      case '7':
        return 'Picked Up';
      case '9':
        return 'Arrived in DC';
      case '10':
        return 'Out for Delivery';
      case '15':
        return 'Process for Return';
      case '16':
        return 'Returned to Shipper';
      case '17':
        return 'Hub Transfer';
      case '19':
        return 'Duplicate';
      case '20':
        return 'Returned to DC';
      case '21':
        return 'Not Recieved';
      case '22':
        return 'Rescheduled';
      case '23':
        return 'Reattempt';
      default:
        return console.log('Now what now?');
    }     
  };

  return (
    <>
      <Autocomplete
        multiple
        key={props.clear}
        onInputChange={clearChecked}
        options={status}
        disableCloseOnSelect
        size="small"
        getOptionLabel={status => getStatusName(status)}
        filterSelectedOptions={true}
        onChange={(event, status) => props.handleStatus(status)}
        renderOption={status => (
          <div key={status} style={{ width: '100%' }}>
            <span>{getStatusName(status)}</span>
          </div>
        )}
        limitTags={3}
        renderInput={params => <TextField {...params} label="Status" />}
      />
    </>
  );
};
export default StatusFilter;
