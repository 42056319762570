import React from 'react';
import { FormGroup, FormControlLabel, Radio } from '@material-ui/core';

export default function LabelSizeSwitch(props) {
  const { labelSize } = props;
  return (
    <>
      <FormGroup className="d-flex flex-row">
        <FormControlLabel
          control={
            <Radio
              checked={labelSize === 'Stickers'}
              onChange={props.handleLabelSize}
              value="Stickers"
              color="primary"
              name="labelSize"
            />
          }
          label={'Stickers'}
        />
        <FormControlLabel
          control={
            <Radio
              checked={labelSize === 'Small'}
              onChange={props.handleLabelSize}
              value="Small"
              color="primary"
              name="labelSize"
            />
          }
          label={'Small'}
        />
        <FormControlLabel
          control={
            <Radio
              checked={labelSize === 'Large'}
              onChange={props.handleLabelSize}
              value="Large"
              color="primary"
              name="labelSize"
              size="small"
            />
          }
          label={'Large'}
        />
      </FormGroup>
    </>
  );
}
