export default task_status => {
  let task_class = null;

  switch (task_status) {
    case '1':
      task_class = 'unassigned';
      break;

    case '2':
      task_class = 'assigned';
      break;

    case '6':
    case '10':
      task_class = 'inTransit';
      break;

    case '3':
    case '7':
      task_class = 'complete';
      break;

    case '4':
    case '5':
    case '15':
    case '16':
      task_class = 'failed';
      break;

    default:
      task_class = 'unassigned';
      break;
  }
  return task_class;
};
