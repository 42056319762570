import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { store } from '../store';

import {
  SET_DATE_RANGE,
  GET_ORDERS,
  EDIT_ORDER,
  CLEAR_ORDER,
  GET_ERRORS,
  GET_LOGIN_ERRORS,
  GET_CSV_ERRORS,
  GET_CSV_HEADER_ERRORS,
  SUBMIT_CSV_REQUEST,
  RECEIVE_CSV_REPLY,
  GET_ORDER_ERRORS,
  SET_NUM_ORDERS,
  ORDER_CLICKED,
  CLEAR_ERRORS,
  ADD_ORDER,
  SHOW_ALERT,
  SUBMIT_REQUEST,
  RECEIVE_REPLY,
  SET_CURRENT_USER,
  USER_LOGOUT,
  USER_LOGIN_LOADING,
  SHOW_CSVMAP_MODAL,
  SHOW_CREATE_ORDER_MODAL,
  SHOW_EDIT_ORDER_MODAL,
  CLOSE_MODAL,
  ADD_SEARCH_QUERY,
  ADD_FILTER,
  TOGGLE_TABLE_DENSE,
  GET_ORDER_UPLOADS,
} from './types';
import checkCsvHeaders from '../validation/checkCsvHeaders';
import { getUserSettings } from './usersActions';

// /////////////////////////////////////////////
// @route  POST api/azdan/users/login
// @discrption  Login User and return JWT Token
// @access  Public
export const loginUser = userData => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: USER_LOGIN_LOADING, payload: true });

  axios
    .post('/api/azdan/login', userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .then(() => {
      dispatch(getUserSettings());
    })
    .catch(err => {
      dispatch({
        type: GET_LOGIN_ERRORS,
        payload: err.response.data,
      });

      dispatch({ type: USER_LOGIN_LOADING, payload: false });
    });
};

// ////////////////////////////////////
// @route  GET api/azdan/orders/
// @discrption  Get orders
// @access  Private
export const getOrders = (from, to, searchType) => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  // maybe TODO: make so if only from is entered to = from for users looking for one day
  if (from && to) {
    dispatch({ type: SET_DATE_RANGE, payload: [from, to, searchType] });
  }

  const dateRangeArr = store.getState().order.currentDateRange;
  axios
    .get(`/api/azdan/orders?from=${dateRangeArr[0]}&to=${dateRangeArr[1]}&searchType=${dateRangeArr[2]}`)

    .then(res => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data || [],
      });
      dispatch({
        type: RECEIVE_REPLY,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ORDER_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// ///////////////////////////////////////////////////
// @route  POST api/azdan/order/
// @discrption  Create a single new order
// @access  Private
export const createOrders = orderData => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .post('/api/azdan/order/', orderData)
    .then(res => {
      dispatch({
        type: ADD_ORDER,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch({
        type: RECEIVE_REPLY,
      });

      dispatch({
        type: CLOSE_MODAL,
      });

      dispatch(getOrders());
    })
    .catch(err => {
      dispatch({
        type: GET_ORDER_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// ////////////////////////////////////////////////
// @route  POST api/azdan/csvorders/
// @discrption  Create multiple new orders from CSV
// @access  Private
export const createCsvOrders = orderData => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_CSV_REQUEST });

  let newCsvHeadings = orderData[0];
  let csvHeadings = JSON.parse(localStorage.getItem('savedCsvHeaders'));

  const { isValid, errors } = checkCsvHeaders(csvHeadings, newCsvHeadings);

  if (isValid) {
    const body = {
      orderData: orderData,
      csvHeadings: csvHeadings,
    };

    axios
      .post('/api/azdan/csvorders/', body)
      .then(res => {
        dispatch({
          type: ADD_ORDER,
          payload: res.data,
        });
      })
      .then(() => {
        dispatch({
          type: RECEIVE_CSV_REPLY,
        });
      })
      .then(() => {
        dispatch(getOrders());
      })
      .catch(err => {
        if (err.response && err.response.data.error) {
          if (!Array.isArray(err.response.data.error)) {
            err.response.data.error = [err.response.data.error];
          }
          dispatch({
            type: GET_CSV_ERRORS,
            payload: err.response.data,
          });
        }

        dispatch({
          type: RECEIVE_CSV_REPLY,
        });
      });
  } else {
    // open modal
    dispatch({
      type: RECEIVE_CSV_REPLY,
    });

    dispatch({
      type: GET_CSV_HEADER_ERRORS,
      payload: errors.savedHeadings,
    });
  }
};

// ////////////////////////////////////
// @route  PUT api/azdan/orders/edit
// @discrption  Edit order
// @access  Private
export const editOrder = order => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  axios
    .put('/api/azdan/order/edit', order)
    .then(res => {
      dispatch({
        type: EDIT_ORDER,
        payload: res.data,
      });
    })
    .then(() => {
      dispatch({
        type: RECEIVE_REPLY,
      });

      dispatch({
        type: CLOSE_MODAL,
      });

      window.location.reload();
    })
    .catch(err => {
      dispatch({
        type: GET_ORDER_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// /////////////////////////////////////////
// @route  PUT api/azdan/order/cancel
// @discrption  Makes order status canceled
// @access  Private
export const cancelOrder = order => dispatch => {
  if (window.confirm('Are you sure? This order will be cancelled.')) {
    dispatch({ type: SUBMIT_REQUEST });

    axios
      .put('/api/azdan/order/cancel', order)
      .then(res => {
        dispatch({
          type: RECEIVE_REPLY,
        });

        dispatch({
          type: SHOW_ALERT,
          payload: `Order was successfully cancelled.`,
        });

        dispatch({
          type: CLOSE_MODAL,
        });

        // dispatch(getOrders());
      })
      .catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });

        dispatch({
          type: RECEIVE_REPLY,
        });
      });
  }
};

// ////////////////////////////////////
// @route  GET /api/azdan/uploads
// @discrption  Get User's Uploads
// @access  Private
export const getUploads = (from, to, searchType) => dispatch => {
  dispatch(clearErrors());

  dispatch({ type: SUBMIT_REQUEST });

  if (from && to) {
    dispatch({ type: SET_DATE_RANGE, payload: [from, to, searchType] });
  }

  const dateRangeArr = store.getState().order.currentDateRange;
  axios
    .get(`/api/azdan/uploads?from=${dateRangeArr[0]}&to=${dateRangeArr[1]}&searchType=${dateRangeArr[2]}`)

    .then(res => {
      dispatch({
        type: GET_ORDER_UPLOADS,
        payload: res.data || [],
      });
      dispatch({
        type: RECEIVE_REPLY,
      });
    })
    // make upload error handling
    .catch(err => {
      dispatch({
        type: GET_ORDER_ERRORS,
        payload: err.response.data,
      });

      dispatch({
        type: RECEIVE_REPLY,
      });
    });
};

// //////////////////
// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAutenticated to false
  dispatch(setCurrentUser({}));

  window.location.href = '/';

  // dispatch({ type: CLEAR_USERS, payload: null });
  dispatch({ type: USER_LOGOUT });
};

// /////////////////////////
// Opens CSV mapping modal
export const openCsvModal = customerHeadings => dispatch => {
  dispatch({
    type: SHOW_CSVMAP_MODAL,
    payload: customerHeadings[0],
  });
};

// /////////////////////////
// Opens create order modal
export const openCreateModal = () => dispatch => {
  dispatch({
    type: SHOW_CREATE_ORDER_MODAL,
  });
};

// /////////////////////////
// Opens edit order modal
export const openEditModal = order => dispatch => {
  dispatch({
    type: ORDER_CLICKED,
    payload: order,
  });
  dispatch({
    type: SHOW_EDIT_ORDER_MODAL,
  });
};

// /////////////////////////
// Closes any opened modal
export const closeModal = () => dispatch => {
  dispatch({
    type: CLOSE_MODAL,
  });
};

// /////////////////////////
// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

// ////////////////////
// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const updateSearchQueries = query => dispatch => {
  dispatch({
    type: ADD_SEARCH_QUERY,
    payload: query,
  });
};

export const addSearchFilter = query => dispatch => {
  dispatch({
    type: ADD_FILTER,
    payload: query,
  });
};

// ////////////////////////////
// toggles size of table cells
export const toggleTableDense = () => dispatch => {
  dispatch({
    type: TOGGLE_TABLE_DENSE,
  });
};

// ////////////////////////////////////////////
// shows number of orders displaying in footer
export const setNumOfOrders = numOrders => dispatch => {
  dispatch({
    type: SET_NUM_ORDERS,
    payload: numOrders,
  });
};

export const setOrders = newState => dispatch => {
  dispatch({
    type: GET_ORDERS,
    payload: newState,
  });
};

export const clearOrder = () => dispatch => {
  dispatch({
    type: CLEAR_ORDER,
  });
};
