import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import ChartistGraph from 'react-chartist';
import Legend from 'chartist-plugin-legend';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Tooltip, IconButton } from '@material-ui/core';
// core components
import GridItem from './components/GridItem.js';
import GridContainer from './components/GridContainer.js';
import Table from './components/Table.js';
import Card from './components/Card.js';
import CardHeader from './components/CardHeader.js';
import CardBody from './components/CardBody.js';

import { getOrders } from '../../actions/azdanActions';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker.js';
import { emailsSubscriptionChart } from './variables/charts.js';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

const convertFailureToText = failureCode => {
  switch (failureCode) {
    case '1':
      return 'Incorrect Address';
    case '2':
      return 'Customer wants different Date/Time';
    case '3':
      return 'Late Delivery';
    case '4':
      return 'Delay due to Weather/Accident';
    case '5':
      return 'Goods Damaged';
    case '6':
      return 'Shipment Lost';
    case '7':
      return 'No Access to customer';
    case '8':
      return 'Customer not responding';
    case '9':
      return 'Customer Refused Delivery';
    case '10':
      return 'Other';
    case '11':
      return 'Predictive Delivery Delay';
    case '12':
      return 'Parcel Undeliverable';
    case '13':
      return 'Unable to contact customer';
    case '14':
      return 'Return to Shipper';
    case '15':
      return 'Pickup Cancelled';
    case '16':
      return 'Shipment not received';
    case '17':
      return 'Customer Need Tomorrow';
    default:
      return 'Unknown';
  }
};

function TableList(props) {
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [graphData, setGraphData] = useState({ label: [], series: [] });
  const [footerData, setFooterData] = useState([]);
  const [failureData, setFailureData] = useState({ header: [], body: [] });
  const { orders } = props;

  useEffect(() => {
    if (props.orders === null) {
      props.getOrders();
    }
  }, []);

  useEffect(() => {
    const data = generateChartData();
    setChartData(data);
  }, [orders]);

  useEffect(() => {
    const graphData = generateGraphData();
    setGraphData(graphData);
  }, [chartData]);

  const generateGraphData = () => {
    const graphData = {
      labels: [],
      series: [[], [], [], [], [], [], [], [], []],
    };

    chartData.forEach(row => {
      graphData.labels.push(row[0]);
      graphData.series[0].push(row[1]);
      graphData.series[1].push(row[2]);
      graphData.series[2].push(row[3]);
      graphData.series[3].push(row[4]);
      graphData.series[4].push(row[5]);
      graphData.series[5].push(row[6]);
      graphData.series[6].push(row[7]);
      graphData.series[7].push(row[8]);
      graphData.series[8].push(row[9]);
    });
    return graphData;
  };

  const calculateExcessAttempts = summary => {
    const summaryWithExcessAttempts = summary.slice();
    const excessAttempts = summary[6] - (summary[4] + summary[5]);
    summaryWithExcessAttempts[7] = excessAttempts;
    return summaryWithExcessAttempts;
  };

  // summary and data arrays are columns for table and chart each index represents -
  // [Picked Up, Arrived in DC, In Transit, Completed, Failed, No. of Attempts, Excess Attempts, Total]
  const generateChartData = () => {
    const data = {};
    const summary = [0, 0, 0, 0, 0, 0, 0, 0, 0];
    const failureReasons = {};
    let orderList = props.orders;
    if (orderList === null) {
      orderList = [];
    }
    orderList.forEach(order => {
      if (!data[order.task_completeafterdate]) {
        data[order.task_completeafterdate] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
      }

      switch (order.task_status) {
        // Picked Up
        case '7':
          summary[0]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][0]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;
          break;
        // Arrived in DC
        case '9':
          summary[1]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][1]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;
          break;
        // Out for Delivery
        case '10':
          summary[2]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][2]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;
          break;
        // In Transit
        case '6':
          summary[3]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][3]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;
          break;
        // Completed
        case '3':
          summary[4]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][4]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;
          break;
        //Failed
        case '5':
          summary[5]++;
          summary[8]++;
          summary[6] += order.task_noofattempts;
          data[order.task_completeafterdate][5]++;
          data[order.task_completeafterdate][6] += order.task_noofattempts;
          data[order.task_completeafterdate][8]++;

          const failReason = convertFailureToText(order.task_failurereason);
          if (!failureReasons[failReason]) {
            failureReasons[failReason] = 1;
          } else {
            failureReasons[failReason]++;
          }
          break;

        default:
          break;
      }
    });

    if (failureReasons) {
      setFailureData({
        header: Object.keys(failureReasons),
        body: [Object.values(failureReasons)],
      });
    }
    const summaryWithAttempts = calculateExcessAttempts(summary);

    for (let order in data) {
      data[order] = calculateExcessAttempts(data[order]);
    }

    setFooterData(summaryWithAttempts);

    const keys = Object.keys(data);
    const vals = Object.values(data);
    const chartData = [];

    keys.forEach((date, i) => {
      chartData.push([date, ...vals[i]]);
    });

    return chartData;
  };

  console.log('failureReasons: ', failureData, 'chartData: ', chartData);
  return (
    <>
      <div
        style={{ marginLeft: '-10px', display: 'flex', alignItems: 'center' }}
      >
        <CSVLink
          headers={[
            'Date',
            'Picked Up',
            'Arrived in DC',
            'Out for Delivery',
            'In Transit',
            'Completed',
            'Failed',
            'No. of Attempts',
            'Excess Attempts',
            'Total',
          ]}
          data={chartData}
          filename={'tcorp-overview-report.csv'}
          className="ml-2"
        >
          <Tooltip title="Download CSV" aria-label="Download CSV">
            <IconButton
              varient="contained"
              color="primary"
              size="medium"
              aria-label="export-csv"
              type="button"
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
        <CustomDatePicker />
      </div>
      <GridContainer style={{ height: '87vh', overflow: 'scroll' }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: graphData.labels,
                  series: graphData.series,
                }}
                type="Bar"
                options={{
                  low: 0,
                  high: Math.max.apply(Math, graphData.series[6]),
                  plugins: [
                    Legend({
                      position: 'top',
                      legendNames: [
                        'Picked Up',
                        'Arrived in DC',
                        'Out for Delivery',
                        'In Transit',
                        'Completed',
                        'Failed',
                        'No. of Attempts',
                        'Excess Attempts',
                        'Total',
                      ],
                    }),
                  ],
                }}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
              <div className="d-flex justify-content-between align-items-end mt-5">
                <div>
                  <h4 className={classes.cardTitleWhite}>
                    Client Overview Report
                  </h4>
                  <p className={classes.cardCategoryWhite}>
                    Here you can view a summary of all activity for a selected
                    time period
                  </p>
                </div>
                <div className="complete-percentage">
                  Completion {(footerData[4] / footerData[6]).toFixed(2) * 100}%
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  'Date',
                  'Picked Up',
                  'Arrived in DC',
                  'Out for Delivery',
                  'In Transit',
                  'Completed',
                  'Failed',
                  'No. of Attempts',
                  'Excess Attempts',
                  'Total',
                ]}
                tableData={chartData}
                footerData={footerData}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <div className="d-flex justify-content-between align-items-end">
                <h4 className={classes.cardTitleWhite}>Failure Reasons</h4>
              </div>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={failureData.header}
                tableData={failureData.body}
                // footerData={footerData}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    orders: state.order.orderList,
  };
};

export default connect(mapStateToProps, { getOrders })(TableList);
// ////////////////////
// Failure Reasons
// 1	Incorrect Address
// 2	Customer wants different Date/Time
// 3	Late Delivery
// 4	Delay due to Weather/Accident
// 5	Goods Damaged
// 6	Shipment Lost
// 7	No Access to customer
// 8	Customer not responding
// 9	Customer Refused Delivery
// 10	Other
// 11	Predictive Delivery Delay
// 12	Parcel Undeliverable
// 13	Unable to contact customer
// 14	Return to Shipper
// 15	Pickup Cancelled
// 16	Shipment not received
// 17	Customer Need Tomorrow
