import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Badge,
} from '@material-ui/core';

import generateTaskStatus from '../../utils/generateTaskStatus';
import OrderFilter from './OrderFilter';
import { updateUserSettings } from '../../actions/usersActions';

const StyledBadge = withStyles(theme => ({
  badge: {
    top: '12px',
    right: '97px',
    border: '2px solid #fff',
    padding: '0 3px',
    fontSize: '9px',
  },
}))(Badge);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 135,
    maxWidth: 135,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#b7d157',
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#b7d157',
    },
  },
  selected: {},
}))(props => <Tab {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      color: '#b7d157',
    },
    '&:focus': {
      color: '#b7d157',
    },
  },
  selected: {
    '&.Mui-selected': {
      color: '#b7d157',
    },
  },
  indicator: {
    background: '#b7d157',
  },
  toolbar: {
    background: '#fff',
    color: '#222',
    boxShadow: '1px 2px 5px 0px rgba(221, 221, 221, 1)',
  },
}));

const searchTerms = {
  task_conscity: 'CITY',
  task_consdistrict: 'DISTRICT',
  task_status: 'STATUS',
  task_consrating: 'RATING',
  task_shippaymentmethod: 'PAYMENT METHOD',
};

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  // show Lable: searchTerm, searchTerm, Label: SearchTearm, SearchTerm
  const getToolTip = (queryData, text) => {
    const savedKeys = Object.keys(queryData[text]);
    const savedValues = Object.values(queryData[text]);
    const result = savedKeys.map((key, index) => {
      if (key === 'task_status') {
        let taskStatus = savedValues[index].map(val => {
          return generateTaskStatus(val);
        });
        return `STATUS: ${taskStatus.join(', ')}`;
      }
      return `${searchTerms[key]}: ${savedValues[index].join(', ')}`;
    });
    return result.join(' ');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deleteSavedFilter = clicked => {
    if (window.confirm('Are you sure you want to delete this filter?')) {
      const savedQueries = JSON.parse(localStorage.getItem('queries'));
      delete savedQueries[clicked];

      localStorage.setItem('queries', JSON.stringify(savedQueries));
      props.updateUserSettings('queries', savedQueries);
    }
  };

  const queries = localStorage.getItem('queries');
  let query_data = JSON.parse(queries);

  let drawer_data = [];
  for (let item in query_data) {
    drawer_data.push(item);
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.toolbar} position="static">
        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <AntTab
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            label="Filter"
            {...a11yProps(0)}
          />
          <AntTab
            {...a11yProps(1)}
            classes={{ root: classes.root, selected: classes.selected }}
            label={
              <StyledBadge
                max={99}
                badgeContent={drawer_data.length}
                color="primary"
              >
                <span className="filterBtnContent">Saved Filters</span>
              </StyledBadge>
            }
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="d-flex flex-column filter-inputs">
          <OrderFilter
            margin="mb-2"
            handleMultiSearch={props.handleMultiSearch}
            changeQueryName={props.changeQueryName}
            currentOrders={props.currentOrders}
            setValue={() => setValue(value + 1)}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          {drawer_data.map((text, index) => (
            <ListItem
              button
              key={text + index}
              onClick={e =>
                props.handleMultiSearch(query_data[e.target.innerText])
              }
            >
              <Tooltip
                title={`Filters for ${getToolTip(query_data, text)}`}
                aria-label="add"
              >
                <ListItemText primary={text} />
              </Tooltip>

              <DeleteOutlinedIcon onClick={e => deleteSavedFilter(text)} />
            </ListItem>
          ))}
        </List>
      </TabPanel>
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { updateUserSettings })(SimpleTabs);
