import React from 'react';

import './Footer.css';

const Footer = () => {
  return (
    <footer className="Footer text-dark d-flex justify-content-center">
      <span>Copyright &copy; {new Date().getFullYear()} TRANSCORP</span>
    </footer>
  );
};

export default Footer;
