import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';

import SideDrawerTabs from './SideDrawerTabs';
import TableNav from '../OrdersTableNav/OrdersTableNav';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 0,
  },
  drawerContainer: {
    // overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    overflowX: 'scroll',
    overflowY: 'hidden',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function SideDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const queries = localStorage.getItem('queries');
  let query_data = JSON.parse(queries);
  var drawer_data = [];

  for (let item in query_data) {
    drawer_data.push(item);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Divider />
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* ///////// SideDrawerTabs GO HERE //////////////////// */}
          <SideDrawerTabs
            savedFilters={drawer_data || []}
            searchQuery={props.searchQuery}
            handleMultiSearch={props.handleMultiSearch}
            toggleDrawer={toggleDrawer}
            currentOrders={props.currentOrders}
          />
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className="filter-nav">
          <Tooltip title="Collapse Filters" aria-label="Collapse Filters">
            <IconButton
              color="primary"
              size="medium"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={`${clsx(
                classes.menuButton,
                open && classes.hide
              )} ml-2`}
            >
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Tooltip>

          <TableNav
            tableColumnsData={props.tableColumnsData}
            setColumnsHidden={props.setColumnsHidden}
            onChange={props.onChange}
            onChangeSearchValue={props.onChangeSearchValue}
            searchValue={props.searchValue}
            navigate={props.navigate}
            activeOrders={props.activeOrders}
            closedOrders={props.closedOrders}
            allOrders={props.allOrders}
            currentOrders={props.currentOrders}
            tableHeaders={props.tableHeaders}
          />
        </div>
        {/* @table */}
        <div className="table-responsive">
          <div className="tcorp-table-container">{props.tableBody}</div>
        </div>
      </main>
    </div>
  );
}
