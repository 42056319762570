import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  Button,
} from '@material-ui/core';

import { updateUserSettings } from '../../actions/usersActions';
import StatusFilter from './StatusFilter';
import RatingFilter from './RatingFilter';
import CityFilter from './CityFilter';
import DistrictFilter from './DistrictFilter';
import '../Orders/Orders.css';

class OrderFilter extends Component {
  constructor() {
    super();
    this.state = {
      task_status: '',
      task_consrating: '',
      task_shippaymentmethod: '',
      task_consdistrict: '',
      task_conscity: '',
      task_endtime: '',
      task_completeafterdate: '',
      query_name: '',
      errors: false,
      clear: false,
    };

    this.checkName = this.checkName.bind(this);
  }

  resetFilters = () => {
    this.setState(
      {
        task_status: '',
        task_endtime: '',
        task_consrating: '',
        task_shippaymentmethod: '',
        task_completeafterdate: '',
        task_consdistrict: '',
        task_conscity: '',
        query_name: '',
        errors: false,
        clear: !this.state.clear,
      },
      () => this.props.handleMultiSearch('')
    );
  };

  saveQuery() {
    const query_name = this.state.query_name;
    let searchObj = {};
    let queries = { ...this.state };
    delete queries.query_name;
    delete queries.errors;
    delete queries.clear;

    for (let name in queries) {
      if (queries[name]) {
        searchObj[name] = queries[name];
      }
    }

    if (query_name) {
      let savedQueries = {};
      if (JSON.parse(localStorage.getItem('queries'))) {
        savedQueries = JSON.parse(localStorage.getItem('queries'));
        savedQueries[query_name] = searchObj;
      } else {
        savedQueries[query_name] = searchObj;
      }

      localStorage.setItem('queries', JSON.stringify(savedQueries));
      this.props.updateUserSettings('queries', savedQueries);
    }
    this.setState({
      query_name: '',
    });
    this.props.setValue();
  }

  submitSearch = () => {
    const searchInputs = [
      'task_status',
      'task_consrating',
      'task_shippaymentmethod',
      'task_consdistrict',
      'task_conscity',
    ];
    let searchObj = {};
    let queries = { ...this.state };
    for (let name in queries) {
      if (searchInputs.includes(name) && queries[name]) {
        searchObj[name] = queries[name];
      }
    }
    this.props.handleMultiSearch(searchObj);
  };

  handleStatus = async status => {
    await this.setState({
      task_status: status,
    });
  };

  handleRating = async rating => {
    await this.setState({
      task_consrating: rating,
    });
  };

  handleDistrict = async district => {
    await this.setState({
      task_consdistrict: district,
    });
  };

  handleCity = async city => {
    await this.setState({
      task_conscity: city,
    });
  };

  checkName = () => {
    if (this.state.query_name) {
      this.saveQuery();
    } else {
      this.setState({
        errors: true,
      });
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <TextField
          error={this.state.errors}
          type="text"
          className="form-control mt-2"
          label="Enter Name to Save Filters"
          variant="outlined"
          size="small"
          id="query_name"
          value={this.state.query_name}
          name="query_name"
          required={true}
          onChange={this.onChange}
        />
        <Button
          className={this.state.query_name ? '' : 'hide'}
          color="primary"
          variant="contained"
          onClick={this.checkName}
        >
          Save Filter
        </Button>
        <FormControl>
          <CityFilter
            handleCity={this.handleCity}
            clear={this.state.clear}
            cities={this.props.currentOrders.map(
              order =>
                order.task_conscity.charAt(0).toUpperCase() +
                order.task_conscity.slice(1).toLowerCase()
            )}
          />
        </FormControl>
        <FormControl>
          <DistrictFilter
            handleDistrict={this.handleDistrict}
            clear={this.state.clear}
            districts={this.props.currentOrders.map(
              order =>
                order.task_consdistrict.charAt(0).toUpperCase() +
                order.task_consdistrict.slice(1).toLowerCase()
            )}
          />
        </FormControl>
        <FormControl>
          <StatusFilter
            handleStatus={this.handleStatus}
            clear={this.state.clear}
          />
        </FormControl>
        <FormControl>
          <RatingFilter
            handleRating={this.handleRating}
            clear={this.state.clear}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="paymentMethod">Payment Method</InputLabel>
          <Select
            value={this.state.task_shippaymentmethod}
            name="task_shippaymentmethod"
            label="Payment Method"
            onChange={this.onChange}
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'cash'}>Cash</MenuItem>
            <MenuItem value={'credit'}>Credit</MenuItem>
          </Select>
        </FormControl>
        <Button
          className="mt-3"
          disabled={this.state.query_name ? true : false}
          variant="contained"
          color="primary"
          onClick={this.submitSearch}
        >
          See Filtered
        </Button>
        <Button
          variant="outlined"
          color="primary"
          style={{ border: '2px solid', fontWeight: '500' }}
          onClick={this.resetFilters}
        >
          Clear Filters
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { updateUserSettings })(OrderFilter);
