import generateTaskStatus from './generateTaskStatus';

// creates a matrix of arrays with CSV headings being the first array and the data being the rest
// export default (orders, tableColumns) => {
//   const csv = []

//   if (orders) {
//     let headings = tableColumns.map(column => column.key);
//     csv.push(headings)

//     orders.forEach(order => {
//       let newRow = [];

//       for (let i = 0; i < headings.length; i++) {
//         let column = headings[i];

//         if (column === 'task_status') {
//           newRow.push(generateTaskStatus(order[column]));
//         }
//         newRow.push(order[column]);
//       }

//       csv.push(newRow)
//     });
// debugger;
//     return csv;
//   }
// };
// just need to convert task_status from number to words
export default orders => {
  const changedOrders = JSON.parse(JSON.stringify(orders));

  changedOrders.map(order => {
    return (order['task_status'] = generateTaskStatus(order['task_status']));
  });
  return changedOrders;
};
