import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DistrictFilter = props => {
  const districts = [...new Set(props.districts)];

  const clearChecked = () => {
    props.handleDistrict('');
  };

  return (
    <>
      <Autocomplete
        multiple
        key={props.clear}
        onInputChange={clearChecked}
        options={districts}
        disableCloseOnSelect
        size="small"
        getOptionLabel={districts => districts}
        filterSelectedOptions={true}
        onChange={(_, newVal) => props.handleDistrict(newVal)}
        renderOption={district => (
          <div key={district} style={{ width: '100%' }}>
            <span>{district}</span>
          </div>
        )}
        limitTags={3}
        renderInput={params => <TextField {...params} label="District/Area" />}
      />
    </>
  );
};
export default DistrictFilter;
