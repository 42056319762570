import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactDraggable from 'react-draggable';

import { SettingsEthernet } from '@material-ui/icons';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

// TODO: Remove MoreVert reference and replace with <Icon>ArrowSplitVertical</Icon>
// React-Beautiful-dnd code
const grid = 1;
const getListStyle = isDraggingOver => ({
  display: 'flex',
  padding: grid,
  overflow: 'none',
});

// React-Beautiful-dnd code
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  paddingBottom: grid * 2,
  margin: `0 ${grid}px 0 0`,
  display: 'inline-block',

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      columnData,
      numSelected,
      rowCount,
      onSelectAllClick,
      orderBy,
      order,
      handleResizeColumn,
      handleReorderColumnData,
    } = this.props;

    return (
      <DragDropContext onDragEnd={handleReorderColumnData}>
        <TableHead>
          <TableRow
            component={Droppable}
            droppableId="droppable"
            direction="horizontal"
            style={{ padding: 0 }}
          >
            {(provided, snapshot) => (
              <tr
                key={snapshot.toString()}
                ref={provided.innerRef}
                style={{
                  ...getListStyle(snapshot.isDraggingOver),
                  padding: 0,
                }}
                {...provided.droppableProps}
              >
                <TableCell
                  padding="checkbox"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={numSelected === rowCount}
                    onChange={onSelectAllClick}
                  />
                </TableCell>
                {columnData.map((column, index) => (
                  <TableCell
                    key={column.id}
                    style={{
                      overflow: 'none',
                      width: `${column.width}px`,
                      paddingLeft: 5,
                      paddingRight: 0,
                    }}
                  >
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, {
                            ...provided.draggableProps.style,
                            display: 'inline-block',
                            width: `${column.width}px` || '100px',
                            paddingTop: '.25rem',
                          })}
                        >
                          <Tooltip title={column.label} aria-label="add">
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={order}
                              onClick={this.createSortHandler(column.id)}
                              style={{
                                width: `${column.width - 50}px`,
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                opacity: '1',
                                margin: '0px 0px 0px 5px',
                              }}
                            >
                              {column.label}
                            </TableSortLabel>
                          </Tooltip>
                          {!snapshot.isDragging && (
                            <ReactDraggable
                              axis="x"
                              defaultClassName="ReactDragHandle"
                              defaultClassNameDragging="ReactDragHandleActive"
                              onStop={(event, data) => {
                                const newWidth = column.width + data.x;
                                return handleResizeColumn(column.id, newWidth);
                              }}
                              position={{
                                x: 0,
                                y: 0,
                              }}
                              zindex={999}
                            >
                              {/* contentEditable is a hack to get Draggable (column sort behavior) to ignore the MoreVert. 
                                 we suppress ContentEditable warning because it's not actually editable */}
                              <SettingsEthernet
                                suppressContentEditableWarning={true}
                                contentEditable={true}
                                style={{
                                  fontSize: '1rem',
                                  verticalAlign: 'center',
                                }}
                              />
                            </ReactDraggable>
                          )}
                        </div>
                      )}
                    </Draggable>
                  </TableCell>
                ))}
                {provided.placeholder}
              </tr>
            )}
          </TableRow>
        </TableHead>
      </DragDropContext>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
