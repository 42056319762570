import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  Timeline,
  TimelineDot,
  TimelineOppositeContent,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
} from '@material-ui/lab';
import {
  Create,
  Assignment,
  AlarmOn,
  AssignmentInd,
  LocalShipping,
  CheckCircle,
  ReportProblem,
  Sms,
} from '@material-ui/icons';
import { Paper, Typography } from '@material-ui/core';

import { formatTimeAndDate } from '../../helpers';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 50px',
  },
  secondaryTail: {
    backgroundColor: '#09d3ef',
  },
  connector: {
    width: '2px',
    flexGrow: '1',
    backgroundColor: '#bdbdbd',
    position: 'absolute',
    top: '-9px',
    height: '20px',
  },
  root: {
    flex: 0.3,
    alignSelf: 'center',
  },
}));

function CustomizedTimeline(props) {
  const classes = useStyles();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    setActivities(props.timeline);
  }, [props.timeline]);

  const getIcon = (activity, i) => {
    let message = activity.message
      .split(' ')
      .slice(0, 2)
      .join(' ');

    let icon = null;

    switch (message.trim()) {
      case 'Created by':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <Create />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Assigned':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#50D66A' }}
              >
                <Assignment />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Edited':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <Create />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Task Picked':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <AlarmOn style={{ color: '#cae25f' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Task Arrived':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector style={{ color: '#4acd62' }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <AssignmentInd style={{ color: '#A434A5' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Task In':
      case 'Task Out':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <LocalShipping style={{ color: '#FF9802' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'SMS Sent':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <Sms style={{ color: '#2296F3' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Task Completed':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector classes={{ root: classes.connector }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <CheckCircle style={{ color: '#4CAF50' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      case 'Task Failed':
        icon = (
          <TimelineItem key={'Activity' + i}>
            <TimelineOppositeContent classes={{ root: classes.root }}>
              <Typography variant="body2" color="textSecondary">
                {formatTimeAndDate(activity.timestamp)}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator
              style={{ color: '#cae25f', alignSelf: 'center' }}
            >
              <TimelineConnector style={{ color: '#4acd62' }} />
              <TimelineDot
                variant="outlined"
                style={{ backgroundColor: '#fff', color: '#cae25f' }}
              >
                <ReportProblem style={{ color: '#F44335' }} />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent style={{ alignSelf: 'center' }}>
              <Paper elevation={3} className={classes.paper}>
                <Typography variant="h6" component="h3">
                  {activity.message
                    .split('Created by ')
                    .slice(0, 1)
                    .join(' ')}
                </Typography>
                <Typography>
                  {`Created by${activity.message.split(' by').slice(1, 2)}`}
                </Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        );
        break;
      default:
        console.log('beep boooop, does not compute: ', activity);
        icon = '';
        break;
    }
    return icon;
  };

  return (
    <Timeline align="left">
      {activities &&
        activities.map((activity, i) => {
          return getIcon(activity, i);
        })}
    </Timeline>
  );
}

const mapStateToProps = state => {
  return {
    timeline: state.order.order.timeline,
  };
};

export default connect(mapStateToProps)(CustomizedTimeline);
