import { formatDate } from '../../helpers';

import generateTaskClass from '../../utils/generateTaskClass';

export default function (orders) {
  const activeOrders = orders.filter(order => order.inactive === false);

  const events = activeOrders.map(order => {
    const task_status = generateTaskClass(order.task_status);
    return {
      id: order.nstaskid,
      title: `${order.task_consigneename}-${task_status}`,
      allDay: true,
      start: formatDate(order.task_completeafterdate),
      end: formatDate(order.task_completebeforedate),
      task_customer: order.task_customer,
      status: task_status,
      consumer: order.task_consigneename,
      order: order,
    };
  });

  return events;
}
