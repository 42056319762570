import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Button from '@material-ui/core/Button';

import districts from '../../assets/uaeCities';
import { formatDate } from '../../helpers';
import { logoutUser } from '../../actions/authActions';
import {
  createOrders,
  clearErrors,
  openCreateModal,
  closeModal,
  clearOrder,
} from '../../actions/azdanActions';

const cities = Object.keys(districts);

class OrderCreate extends Component {
  constructor() {
    super();
    this.state = {
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_conscountryname: 'UAE',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: moment(Date.now()).format('YYYY-MM-DD'),
      task_completeaftertime: '07:00',
      task_completebeforetime: '18:00',
      task_awb: '',
      task_smsnotification: true,
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      task_amazonsfname: '',
      task_amazonshline1: '',
      task_amazonshcity: '',
      task_amazonshcontactph: '',
      task_hangers: 0,
      task_shipmentpackage: '',
      task_type: '',

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
  }

  // @onChange
  onChange(e) {
    if (
      e.target.name === 'task_completeafterdate' ||
      e.target.name === 'task_completeaftertime' ||
      e.target.name === 'task_completebeforetime'
    ) {
      if (e.target.value === '') {
        return;
      } else {
        function calendarFormat(inputDate) {
          return moment(inputDate).format('YYYY-MM-DD');
        }

        const today = calendarFormat(Date.now());

        let completeAfterTime = this.state.task_completeaftertime;
        let completeBeforeTime = this.state.task_completebeforetime;

        switch (e.target.name) {
          case 'task_completeafterdate':
            if (e.target.value < today) {
              return;
            } else {
              this.setState({
                task_completeafterdate: e.target.value,
              });
            }

            break;

          case 'task_completeaftertime':
            if (e.target.value < completeBeforeTime) {
              this.setState({
                task_completeaftertime: e.target.value,
              });
            } else {
              this.setState({
                task_completeaftertime: e.target.value,
              });
            }

            break;

          case 'task_completebeforetime':
            if (completeAfterTime < e.target.value) {
              this.setState({
                task_completebeforetime: e.target.value,
              });
            } else {
              this.setState({
                task_completebeforetime: e.target.value,
              });
            }

            break;

          default:
            console.log('Oh my...');
        }

        return;
      }
    } else if (e.target.name === 'task_consigneename') {
      const regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

      if (e.target.value === '') {
        this.setState({ [e.target.name]: e.target.value });
      } else if (regex.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
      return;
    } else if (e.target.name === 'task_conscontactphone') {
      const regex = /[-\d ()+]+$/u;

      if (e.target.value === '') {
        this.setState({ [e.target.name]: e.target.value });
      } else if (regex.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value });
      }
      return;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  // @onSubmit
  onSubmit(e) {
    e.preventDefault();
    const orderData = {
      task_consigneename: this.state.task_consigneename,
      task_conscontactphone: this.state.task_conscontactphone,
      task_consaddline1: this.state.task_consaddline1,
      task_conscity: this.state.task_conscity,
      task_consdistrict: this.state.task_consdistrict,
      task_totalshipqty: this.state.task_totalshipqty,
      task_details: this.state.task_details,
      task_notes: this.state.task_notes,
      task_codamount: this.state.task_codamount,
      task_completeafterdate: formatDate(this.state.task_completeafterdate),
      task_completeaftertime: this.state.task_completeaftertime,
      task_completebeforetime: this.state.task_completebeforetime,
      task_conscountryname: this.state.task_conscountryname,
      task_awb: this.state.task_awb,
      task_smsnotification: this.state.task_smsnotification,
      task_shippaymentmethod: this.state.task_shippaymentmethod,
      task_customerorderno: this.state.task_customerorderno,
      task_totaldecgrossw: this.state.task_totaldecgrossw,
      task_volume: this.state.task_volume,
      task_deliverytype: this.state.task_deliverytype,
      task_amazonsfname: this.state.task_amazonsfname,
      task_amazonshline1: this.state.task_amazonshline1,
      task_amazonshcity: this.state.task_amazonshcity,
      task_amazonshcontactph: this.state.task_amazonshcontactph,
      task_hangers: this.state.task_hangers,
      task_shipmentpackage: this.state.task_shipmentpackage,
      task_type: this.state.task_type,
    };

    this.props.createOrders(orderData);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modal.createShow !== this.props.modal.createShow) {
      const { order } = this.props.order;
      if (order._id) {
        this.setState({
          task_consigneename: order.task_consigneename,
          task_conscontactphone: order.task_conscontactphone,
          task_consaddline1: order.task_consaddline1,
          task_conscity: order.task_conscity,
          task_consdistrict: order.task_consdistrict,
          task_totalshipqty: order.task_totalshipqty,
          task_details: order.task_details || '',
          task_notes: order.task_notes || '',
          task_codamount: order.task_codamount || '',
          task_completeafterdate: '',
          task_completeaftertime: '',
          task_completebeforetime: '',
          task_awb: '',
          task_smsnotification: order.task_smsnotification || 'true',
          task_shippaymentmethod: order.task_shippaymentmethod || '',
          task_customerorderno: order.task_customerorderno || '',
          task_totaldecgrossw: order.task_totaldecgrossw || '',
          task_volume: order.task_volume || '',
          task_deliverytype: order.task_deliverytype || '',
          task_amazonsfname: order.task_amazonsfname || '',
          task_amazonshline1: order.task_amazonshline1 || '',
          task_amazonshcity: order.task_amazonshcity || '',
          task_amazonshcontactph: order.task_amazonshcontactph || '',
          task_hangers: order.task_hangers || 0,
          task_shipmentpackage: '',
          task_type: order.task_type || '2',

          errors: {},
        });
      } else {
        this.typeahead.getInstance().clear();
        this.setState({
          task_consigneename: '',
          task_conscontactphone: '',
          task_consaddline1: '',
          task_conscity: '',
          task_consdistrict: '',
          task_totalshipqty: '',
          task_details: '',
          task_notes: '',
          task_codamount: '',
          task_completeafterdate: moment(Date.now()).format('YYYY-MM-DD'),
          task_completeaftertime: '07:00',
          task_completebeforetime: '18:00',
          task_awb: '',
          task_smsnotification: true,
          task_shippaymentmethod: '',
          task_customerorderno: '',
          task_totaldecgrossw: '',
          task_volume: '',
          task_deliverytype: '',
          task_amazonsfname: '',
          task_amazonshline1: '',
          task_amazonshcity: '',
          task_amazonshcontactph: '',
          task_hangers: 0,
          task_shipmentpackage: '',
          task_type: '2',

          errors: {},
        });
      }
    }

    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }
  }

  toggleCheck(e) {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  }

  onClose() {
    this.props.closeModal();

    this.setState({
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: '',
      task_completeaftertime: '',
      task_completebeforetime: '',
      task_awb: '',
      task_smsnotification: '',
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      task_amazonsfname: '',
      task_amazonshline1: '',
      task_amazonshcity: '',
      task_amazonshcontactph: '',
      task_hangers: 0,
      task_shipmentpackage: '',
      task_type: '',

      errors: {},
    });

    this.props.clearErrors();
    this.props.clearOrder();
  }

  render() {
    const { auth, order, errors } = this.props;

    // TYPEAHEAD loop for finding/suggesting previous
    let districtOptions = [];
    if (this.state.task_conscity) {
      for (let i in districts[this.state.task_conscity]) {
        districtOptions.push(districts[this.state.task_conscity][i].geofence);
      }
    }

    return (
      <div className={`modal fade-in-2 ${this.props.modal.createShow}`}>
        <div className="modal-dialog modal-lg" role="dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title text-primary font-weight-bold"
                id="exampleModalLabel"
              >
                Placing New Order for: {auth.user.name}
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {/* FORM */}
              <form noValidate>
                <h5 className="text-primary">Recipient</h5>

                <div className="row">
                  {/* @customer name */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Customer Name <em> - Required</em>
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_consigneename,
                      })}
                      name="task_consigneename"
                      value={this.state.task_consigneename}
                      onChange={this.onChange}
                      maxLength="55"
                      required
                    />

                    {errors.task_consigneename && (
                      <small className="text-danger" data-test="name-error">
                        {errors.task_consigneename}
                      </small>
                    )}
                  </div>
                  {/* @customer Mobile */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Customer Mobile <em> - Required</em>
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_conscontactphone,
                      })}
                      name="task_conscontactphone"
                      value={this.state.task_conscontactphone}
                      onChange={this.onChange}
                      maxLength="20"
                      required
                    />

                    {errors.task_conscontactphone && (
                      <small
                        className="invalid-feedback"
                        data-test="mobile-error"
                      >
                        {errors.task_conscontactphone}
                      </small>
                    )}
                  </div>
                </div>

                <div className="dropdown-divider" />

                <h5 className="text-primary">Sender</h5>

                <div className="row">
                  {/* @merchant name */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Ship From Name
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_amazonsfname,
                      })}
                      name="task_amazonsfname"
                      value={this.state.task_amazonsfname}
                      onChange={this.onChange}
                      maxLength="55"
                      required
                    />

                    {errors.task_amazonsfname && (
                      <small className="text-danger" data-test="name-error">
                        {errors.task_amazonsfname}
                      </small>
                    )}
                  </div>
                  {/* @mechant Mobile */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Ship From Phone
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_amazonshcontactph,
                      })}
                      name="task_amazonshcontactph"
                      value={this.state.task_amazonshcontactph}
                      onChange={this.onChange}
                      maxLength="20"
                      required
                    />

                    {errors.task_amazonshcontactph && (
                      <small
                        className="invalid-feedback"
                        data-test="mobile-error"
                      >
                        {errors.task_amazonshcontactph}
                      </small>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* @merchant address */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Ship From Address
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_amazonshline1,
                      })}
                      name="task_amazonshline1"
                      value={this.state.task_amazonshline1}
                      onChange={this.onChange}
                      required
                    />

                    {errors.task_amazonshline1 && (
                      <small className="text-danger" data-test="name-error">
                        {errors.task_amazonshline1}
                      </small>
                    )}
                  </div>
                  {/* @merchant city */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Ship From City
                    </small>
                    <Typeahead
                      ref={typeahead => (this.typeahead = typeahead)}
                      inputProps={{ 'data-test': 'city-input' }}
                      id="task_amazonshcity"
                      name="task_amazonshcity"
                      value={this.state.task_amazonshcity}
                      onInputChange={value => {
                        this.setState({ task_amazonshcity: value });
                      }}
                      onChange={value => {
                        if (value !== undefined) {
                          this.setState({ task_amazonshcity: value[0] });
                        }
                      }}
                      options={cities}
                      required
                    />

                    {errors.task_amazonshcity && (
                      <small
                        className="invalid-feedback"
                        data-test="mobile-error"
                      >
                        {errors.task_amazonshcity}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* @Hangers */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">Hangers</small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_hangers,
                      })}
                      name="task_hangers"
                      value={this.state.task_hangers}
                      onChange={this.onChange}
                      maxLength="55"
                      required
                    />

                    {errors.task_hangers && (
                      <small className="text-danger" data-test="name-error">
                        {errors.task_hangers}
                      </small>
                    )}
                  </div>
                  {/* @Shipment Packages */}
                  <div className="form-group col-lg-6">
                    <small className="form-text text-muted">
                      Shipment Packages - <em>Separate with comas</em>
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_shipmentpackage,
                      })}
                      name="task_shipmentpackage"
                      value={this.state.task_shipmentpackage}
                      onChange={this.onChange}
                      required
                    />

                    {errors.task_shipmentpackage && (
                      <small
                        className="invalid-feedback"
                        data-test="mobile-error"
                      >
                        {errors.task_shipmentpackage}
                      </small>
                    )}
                  </div>
                </div>

                <div className="dropdown-divider" />

                <h5 className="text-primary mt-3">Order Details</h5>

                <div className="row">
                  {/* @Delivery Date */}
                  <div className="form-group col-lg-4">
                    <small className="form-text text-muted">
                      Delivey Date<em> - Required</em>
                    </small>
                    <input
                      type="date"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_completeafterdate,
                      })}
                      name="task_completeafterdate"
                      value={this.state.task_completeafterdate}
                      onChange={this.onChange}
                    />

                    {errors.task_completeafterdate && (
                      <small className="invalid-feedback">
                        {errors.task_completeafterdate}
                      </small>
                    )}
                  </div>

                  {/* @Deliver After Time */}
                  <div className="form-group col-lg-4">
                    <small className="form-text text-muted">
                      Deliver After Time<em> - Required</em>
                    </small>
                    <input
                      type="time"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_completeaftertime,
                      })}
                      name="task_completeaftertime"
                      value={this.state.task_completeaftertime}
                      onChange={this.onChange}
                      required
                    />

                    {errors.task_completeaftertime && (
                      <small className="invalid-feedback">
                        {errors.task_completeaftertime}
                      </small>
                    )}
                  </div>

                  {/* @Deliver Before Time */}
                  <div className="form-group col-lg-4">
                    <small className="form-text text-muted">
                      Deliver Before Time<em> - Required</em>
                    </small>
                    <input
                      type="time"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_completebeforetime,
                      })}
                      name="task_completebeforetime"
                      value={this.state.task_completebeforetime}
                      onChange={this.onChange}
                      min={this.state.task_completeaftertime}
                      required
                    />

                    {errors.task_completebeforetime && (
                      <small className="invalid-feedback">
                        {errors.task_completebeforetime}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* @Package Details */}
                  <div className="form-group col-12">
                    <small className="form-text text-muted">
                      Package Details
                    </small>

                    <textarea
                      className="form-control"
                      name="task_details"
                      id="task_details"
                      onChange={this.onChange}
                      value={this.state.task_details}
                    />

                    {errors.task_details && (
                      <small className="invalid-feedback">
                        {errors.task_details}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* @No. of Packages */}
                  <div className="form-group col-lg-3 pr-lg-1">
                    <small className="form-text text-muted">
                      No. of Packages<em> - Required</em>
                    </small>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_totalshipqty,
                      })}
                      name="task_totalshipqty"
                      value={this.state.task_totalshipqty}
                      onChange={this.onChange}
                      min="1"
                      required
                    />

                    {errors.task_totalshipqty && (
                      <small
                        className="invalid-feedback"
                        data-test="number-packages-error"
                      >
                        {errors.task_totalshipqty}
                      </small>
                    )}
                  </div>

                  {/* @Weight*/}
                  <div className="form-group col-lg-3 px-lg-1">
                    <small className="form-text text-muted">Weight</small>
                    <input
                      className={classnames('form-control', {
                        'is-invalid': errors.task_totaldecgrossw,
                      })}
                      type="number"
                      id="task_totaldecgrossw"
                      name="task_totaldecgrossw"
                      value={this.state.task_totaldecgrossw}
                      onChange={this.onChange}
                      min="0"
                    />

                    {errors.task_totaldecgrossw && (
                      <small className="invalid-feedback">
                        {errors.task_totaldecgrossw}
                      </small>
                    )}
                  </div>

                  {/* @Volume*/}
                  <div className="form-group col-lg-3 px-lg-1">
                    <small className="form-text text-muted">Volume</small>
                    <input
                      className={classnames('form-control', {
                        'is-invalid': errors.task_volume,
                      })}
                      id="task_volume"
                      name="task_volume"
                      value={this.state.task_volume}
                      onChange={this.onChange}
                      type="number"
                      min="0"
                    />

                    {errors.task_volume && (
                      <small className="invalid-feedback">
                        {errors.task_volume}
                      </small>
                    )}
                  </div>

                  {/* @AWB */}
                  <div className="form-group col-lg-3 pr-lg-1">
                    <small className="form-text text-muted">AWB</small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_awb,
                      })}
                      name="task_awb"
                      value={this.state.task_awb}
                      onChange={this.onChange}
                      maxLength="100"
                    />

                    {errors.task_awb && (
                      <small className="invalid-feedback">
                        {errors.task_awb}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* @Reference Number */}
                  <div className="form-group col-lg-3 pr-lg-1">
                    <small className="form-text text-muted">
                      Reference Number
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_customerorderno,
                      })}
                      name="task_customerorderno"
                      value={this.state.task_customerorderno}
                      onChange={this.onChange}
                    />

                    {errors.task_customerorderno && (
                      <small className="invalid-feedback">
                        {errors.task_customerorderno}
                      </small>
                    )}
                  </div>

                  {/* @Payment to be Collected */}
                  <div className="form-group col-lg-3 px-lg-1">
                    <small className="form-text text-muted">
                      Payment to be Collected
                    </small>
                    <input
                      className={classnames('form-control', {
                        'is-invalid': errors.task_codamount,
                      })}
                      id="task_codamount"
                      name="task_codamount"
                      value={this.state.task_codamount}
                      onChange={this.onChange}
                    />

                    {errors.task_codamount && (
                      <small className="invalid-feedback">
                        {errors.task_codamount}
                      </small>
                    )}
                  </div>

                  {/* @Payment Method */}
                  <div className="form-group col-lg-3 pr-lg-1">
                    <small className="form-text text-muted">
                      Mode of Payment
                    </small>
                    <select
                      className={classnames('form-control', {
                        'is-invalid': errors.task_shippaymentmethod,
                      })}
                      id="task_shippaymentmethod"
                      name="task_shippaymentmethod"
                      value={this.state.task_shippaymentmethod}
                      onChange={this.onChange}
                    >
                      <option value="" defaultValue></option>
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                    </select>

                    {errors.task_shippaymentmethod && (
                      <small className="invalid-feedback">
                        {errors.task_shippaymentmethod}
                      </small>
                    )}
                  </div>
                  {/* @Delivery Type */}
                  <div className="form-group col-lg-3 pr-lg-1">
                    <small className="form-text text-muted">
                      Delivery Type
                    </small>
                    <select
                      className={classnames('form-control', {
                        'is-invalid': errors.task_deliverytype,
                      })}
                      id="task_deliverytype"
                      name="task_deliverytype"
                      value={this.state.task_deliverytype}
                      onChange={this.onChange}
                      required
                    >
                      <option value="nextDay" defaultValue>
                        Next Day
                      </option>
                      <option value="sameDay">Same Day</option>
                    </select>

                    {errors.task_deliverytype && (
                      <small className="invalid-feedback">
                        {errors.task_deliverytype}
                      </small>
                    )}
                  </div>
                </div>

                <div className="row">
                  {/* @Order Type */}
                  <div className="form-group col-lg-3 px-lg-1">
                    <small className="form-text text-muted">Order Type</small>
                    <select
                      className={classnames('form-control', {
                        'is-invalid': errors.task_deliverytype,
                      })}
                      id="task_type"
                      name="task_type"
                      value={this.state.task_type}
                      onChange={this.onChange}
                      required
                    >
                      <option value="2" defaultValue>
                        Delivery
                      </option>
                      <option value="1">Pick Up</option>
                    </select>

                    {errors.task_type && (
                      <div className="invalid-feedback">{errors.task_type}</div>
                    )}
                  </div>
                </div>

                <div className="dropdown-divider" />

                <div className="row">
                  {/* @address */}
                  <div className="form-group col-lg-9">
                    <small className="form-text text-muted">
                      Address<em> - Required</em>
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_consaddline1,
                      })}
                      name="task_consaddline1"
                      value={this.state.task_consaddline1}
                      onChange={this.onChange}
                      maxLength="400"
                      required
                    />

                    {errors.task_consaddline1 && (
                      <small
                        className="invalid-feedback"
                        data-test="address-error"
                      >
                        {errors.task_consaddline1}
                      </small>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <small className="form-text text-muted">
                      SMS Notifications
                    </small>
                    <div className="sms-container">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="task_smsnotification"
                        name="task_smsnotification"
                        checked={this.state.task_smsnotification}
                        onChange={this.toggleCheck}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* @City */}
                  <div className="form-group col-lg-4 px-lg-1" data-test="city">
                    <small className="form-text text-muted">
                      City/Emirate<em> - Required</em>
                    </small>
                    <Typeahead
                      ref={typeahead => (this.typeahead = typeahead)}
                      inputProps={{ 'data-test': 'city-input' }}
                      id="task_conscity"
                      name="task_conscity"
                      placeholder={this.state.task_conscity}
                      value={this.state.task_conscity}
                      onInputChange={value => {
                        this.setState({ task_conscity: value });
                      }}
                      onChange={value => {
                        if (value !== undefined) {
                          this.setState({ task_conscity: value[0] });
                        }
                      }}
                      options={cities}
                      required
                    />

                    {errors.task_conscity && (
                      <small className="red-text" data-test="city-error">
                        {errors.task_conscity}
                      </small>
                    )}
                  </div>
                  {/* @District */}
                  <div
                    className="form-group col-lg-4 pl-lg-1"
                    data-test="district"
                  >
                    <small className="form-text text-muted">
                      District/Area<em> - Required</em>
                    </small>
                    <Typeahead
                      ref={typeahead => (this.typeahead = typeahead)}
                      disabled={this.state.task_conscity === '' ? true : false}
                      placeholder={this.state.task_consdistrict}
                      // className={classnames({
                      //   'red-border': errors.task_consdistrict,
                      // })}
                      inputProps={{ 'data-test': 'district-input' }}
                      id="task_consdistrict"
                      name="task_consdistrict"
                      value={this.state.task_consdistrict}
                      onInputChange={value => {
                        this.setState({ task_consdistrict: value });
                      }}
                      onChange={value => {
                        if (value !== undefined) {
                          this.setState({ task_consdistrict: value[0] });
                        }
                      }}
                      options={districtOptions}
                      required
                    />

                    {errors.task_consdistrict && (
                      <small className="red-text" data-test="district-error">
                        {errors.task_consdistrict}
                      </small>
                    )}
                  </div>

                  {/* @country */}
                  <div className="form-group col-lg-4">
                    <small className="form-text text-muted">
                      Country<em> - Required</em>
                    </small>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.task_conscountryname,
                      })}
                      name="task_conscountryname"
                      defaultValue={this.state.task_conscountryname}
                      maxLength="400"
                      required
                    />

                    {errors.task_conscountryname && (
                      <small className="invalid-feedback">
                        {errors.task_conscountryname}
                      </small>
                    )}
                  </div>
                </div>

                <div className="dropdown-divider" />

                <div className="row">
                  <div className="form-group col-12">
                    <small className="form-text text-muted">
                      Delivery Notes
                    </small>

                    <textarea
                      className="form-control"
                      name="task_notes"
                      id="task_notes"
                      onChange={this.onChange}
                      value={this.state.task_notes}
                    />

                    {errors.task_notes && (
                      <small className="invalid-feedback">
                        {errors.task_notes}
                      </small>
                    )}
                  </div>
                </div>

                <div className="modal-footer pb-0 pr-0">
                  <Button
                    data-test="create-order"
                    disabled={order.submitInProgress ? true : false}
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={this.onSubmit}
                  >
                    Create Order
                  </Button>

                  <Button
                    type="button"
                    variant="contained"
                    data-dismiss="modal"
                    onClick={this.onClose}
                  >
                    Close
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  }
}

OrderCreate.propTypes = {
  createOrders: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  success: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  modal: state.modal,
  errors: state.errors.orderErrors,
  success: state.success,
  auth: state.auth,
  order: state.order,
});

export default connect(mapStateToProps, {
  createOrders,
  openCreateModal,
  closeModal,
  clearErrors,
  logoutUser,
  clearOrder,
})(withRouter(OrderCreate));
