// const isEmpty = require('./is-empty');

const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);
// validators and sanitizers for new orders go here
module.exports = function validateCsvHeaders(savedCsvHeadings, newCsvHeadings) {
  let errors = {};
  const savedUserHeadings = Object.keys(savedCsvHeadings);
  const transCorpHeadings = Object.values(savedCsvHeadings);

  if (newCsvHeadings) {
    savedUserHeadings.forEach(heading => {
      if (!newCsvHeadings.includes(heading)) {
        errors.savedHeadings = [
          {
            msg: `The headings in this CSV do not match your saved headings. Please make sure you're attempting to upload the intended CSV OR click "Map Your CSV Headings" to save your new headings.`,
          },
        ];
      }
    });
  }

  if (!transCorpHeadings.includes('task_consigneename')) {
    errors.task_consigneename = 'Customer Name is required';
  }
  if (!transCorpHeadings.includes('task_conscontactphone')) {
    errors.task_conscontactphone = 'Customer Mobile is required';
  }
  if (!transCorpHeadings.includes('task_consaddline1')) {
    errors.task_consaddline1 = 'Address is required';
  }
  if (!transCorpHeadings.includes('task_consdistrict')) {
    errors.task_consdistrict = 'District is required';
  }
  if (!transCorpHeadings.includes('task_conscity')) {
    errors.task_conscity = 'City is required';
  }
  if (!transCorpHeadings.includes('task_conscountryname')) {
    errors.task_conscountryname = 'Country is required';
  }
  if (!transCorpHeadings.includes('task_totalshipqty')) {
    errors.task_totalshipqty = 'Quantity is required';
  }
  if (!transCorpHeadings.includes('task_completeafterdate')) {
    errors.task_completeafterdate = 'Complete After Date is required';
  }
  if (!transCorpHeadings.includes('task_completebeforetime')) {
    errors.task_completebeforetime = 'Complete Before Time is required';
  }
  if (!transCorpHeadings.includes('task_completeaftertime')) {
    errors.task_completeaftertime = 'Complete After Time is required';
  }

  return {
    errors: errors,
    isValid: isEmpty(errors),
  };
};
