import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { Button, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@material-ui/core';

import { closeModal, createCsvOrders, clearErrors } from '../../actions/azdanActions';
import { clearAlert } from '../../actions/alertActions';
import { logoutUser } from '../../actions/authActions';
import { updateUserSettings } from '../../actions/usersActions';
import AlertBox from '../common/AlertBox';
import checkCsvHeaders from '../../validation/checkCsvHeaders';
import tableColumns from '../OrdersTable/TableColumns';

const columns = {
  task_consigneename: 'Customer Name',
  task_conscontactphone: 'Customer Mobile',
  task_consaddline1: 'Address',
  task_conscity: 'City',
  task_consdistrict: 'Area',
  task_conscountryname: 'Country',
  task_totalshipqty: 'No of Packages',
  task_details: 'Package Details',
  task_notes: 'Delivery Notes',
  task_codamount: 'COD Amount',
  task_completeafterdate: 'Delivery Date',
  task_completeaftertime: 'After Time',
  task_completebeforetime: 'Before Time',
  task_awb: 'AWB',
  task_smsnotification: 'SMS',
  task_shippaymentmethod: 'Mode of Payment',
  task_customerorderno: 'Customer Order No',
  task_totaldecgrossw: 'Weight',
  task_volume: 'Volume',
  task_deliverytype: 'Delivery Type',
  task_hangers: 'Hangers',
  task_amazonsfname: 'Ship From Name',
  task_amazonshline1: 'Ship From Address',
  task_amazonshcity: 'Ship From City',
  task_amazonshcontactph: 'Ship From Mobile',
  task_shipmentpackage: 'Shipment Packages',
  task_type: 'Order Type',
};

class OrderCsvMapping extends Component {
  constructor() {
    super();
    this.state = {
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_conscountryname: '',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: '',
      task_completeaftertime: '',
      task_completebeforetime: '',
      task_awb: '',
      task_smsnotification: '',
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      usedHeaderNames: [],
      disableSubmit: true,
      errors: {},
    };

    this.customOptions = [];
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.resetInputs = this.resetInputs.bind(this);
  }

  resetInputs() {
    this.setState({
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_conscountryname: '',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: '',
      task_completeaftertime: '',
      task_completebeforetime: '',
      task_awb: '',
      task_smsnotification: '',
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      usedHeaderNames: [],
      disableSubmit: true,
      errors: [],
    });
    this.customOptions = [];
  }

  // @onChange
  onChange(e) {
    if (this.state[e.target.name]) {
      let usedNames = this.state.usedHeaderNames.slice();
      let index = usedNames.indexOf(this.state[e.target.name]);
      usedNames[index] = e.target.value;
      return this.setState(
        {
          [e.target.name]: e.target.value,
          usedHeaderNames: usedNames,
        },
        () => this.checkRequiredKeys()
      );
    }

    this.setState(
      {
        [e.target.name]: e.target.value,
        usedHeaderNames: [...this.state.usedHeaderNames, e.target.value],
      },
      () => this.checkRequiredKeys()
    );
  }

  checkRequiredKeys() {
    if (
      this.state.task_consigneename &&
      this.state.task_conscontactphone &&
      this.state.task_consaddline1 &&
      this.state.task_conscity &&
      this.state.task_consdistrict &&
      this.state.task_conscountryname &&
      this.state.task_totalshipqty &&
      this.state.task_completeafterdate &&
      this.state.task_completeaftertime &&
      this.state.task_completebeforetime
    ) {
      this.setState({ disableSubmit: false });
    }
  }

  // @onSubmit reverse the state of headers to save in localStorage, headers will be used to make post requests
  onSubmit() {
    const mappedHeaders = this.state;
    const reverseKeyVal = {};

    for (let key in mappedHeaders) {
      if (this.state[key] && key !== 'errors' && key !== 'usedHeaderNames' && key !== 'disableSubmit') {
        reverseKeyVal[mappedHeaders[key]] = key;
      }
    }
    const { errors, isValid } = checkCsvHeaders(reverseKeyVal);

    if (!isValid) {
      this.setState({
        errors: errors,
      });
    } else {
      localStorage.setItem('savedCsvHeaders', JSON.stringify(reverseKeyVal));
      this.props.updateUserSettings('savedCsvHeaders', reverseKeyVal);
    }
    this.onClose();
  }

  onOpen() {
    this.setState({
      errors: {},
    });

    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (this.props.auth.user.exp < currentTime) {
      this.props.logoutUser();
    }

    this.props.clearErrors();
  }

  onClose() {
    this.setState({
      task_consigneename: '',
      task_conscontactphone: '',
      task_consaddline1: '',
      task_conscity: '',
      task_consdistrict: '',
      task_totalshipqty: '',
      task_details: '',
      task_notes: '',
      task_codamount: '',
      task_completeafterdate: '',
      task_completeaftertime: '',
      task_completebeforetime: '',
      task_awb: '',
      task_smsnotification: '',
      task_shippaymentmethod: '',
      task_customerorderno: '',
      task_totaldecgrossw: '',
      task_volume: '',
      task_deliverytype: '',
      disableSubmit: true,
      usedHeaderNames: [],
      errors: {},
    });

    this.props.clearErrors();
    this.props.clearAlert();
    this.props.closeModal();
  }

  autoAssign = () => {
    const tcorpColumnLabels = Object.values(columns);
    const tcorpColumnIds = Object.keys(columns);
    const customerHeadings = this.props.modal.customerHeaders;
    let usedNames = this.state.usedHeaderNames.slice();
    const newState = {};
    customerHeadings.forEach(heading => {
      if (tcorpColumnLabels.includes(heading)) {
        const index = tcorpColumnLabels.indexOf(heading);
        usedNames.push(heading);
        newState[tcorpColumnIds[index]] = heading;
      }
    });
    newState['usedHeaderNames'] = usedNames;
    this.setState(newState, () => this.checkRequiredKeys());
  };

  render() {
    const { errors } = this.state;

    const tcorpHeadings = tableColumns.filter(heading => heading.csvHeading);

    let customerHeadings = this.props.modal.customerHeaders;

    return (
      <React.Fragment>
        <div
          className={`modal fade-in-2 ${this.props.modal.csvShow}`}
          id="MapCsvHeadings"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-md" role="dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary font-weight-bold" id="exampleModalLabel">
                  Please map your CSV headings
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                {/* @alert */}
                <AlertBox />

                {/* FORM */}
                <form noValidate>
                  <div className="d-flex justify-content-between">
                    <h5 className="text-primary">CSV Headings</h5>
                    <Button
                      data-test="auto-map"
                      variant="outlined"
                      size="small"
                      onClick={this.autoAssign}
                      className="mb-1"
                      color="primary"
                    >
                      Auto-Map
                    </Button>
                  </div>
                  <small className="form-text text-muted mb-3">
                    Match your custom headings in your CSV file to the corresponding order attributes. Auto-Map will
                    automatically map some or all of headings if you are using any of the same heading as the table.
                  </small>

                  <h5 className="text-primary">Required Fields</h5>
                  {tcorpHeadings.map((heading, i) => (
                    <div className="row p-2" key={heading.id}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="name-native-disabled">
                          {heading.label} {heading.required ? <em style={{ color: 'red' }}>*</em> : ''}
                        </InputLabel>
                        <Select
                          id={'rquire_' + i}
                          name={`${heading.id}`}
                          value={this.state[heading.id] || ''}
                          onChange={e => this.onChange(e, i)}
                          style={{ fontWeight: '500' }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {customerHeadings.map((header, j) => (
                            <MenuItem
                              key={heading.id + j}
                              value={header}
                              className={classnames({
                                hidden: Object.values(this.state.usedHeaderNames).indexOf(header) > -1 ? 1 : 0,
                              })}
                            >
                              {header}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors[heading.id] && (
                          <FormHelperText className="text-danger">{`${errors[heading.id]}`}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  ))}

                  <div className="modal-footer pb-0 pr-0">
                    <button type="button" className="btn btn-primary" onClick={this.resetInputs}>
                      Reset Inputs
                    </button>

                    <button
                      data-test="save-headings"
                      disabled={this.state.disableSubmit}
                      type="button"
                      className="btn btn-primary"
                      onClick={this.onSubmit}
                    >
                      Save Headings
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={this.props.closeModal}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  success: state.success,
  auth: state.auth,
  order: state.order,
  modal: state.modal,
});

export default connect(mapStateToProps, {
  clearErrors,
  clearAlert,
  logoutUser,
  closeModal,
  createCsvOrders,
  updateUserSettings,
})(withRouter(OrderCsvMapping));
