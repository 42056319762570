import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import ChartistGraph from 'react-chartist';
import Legend from 'chartist-plugin-legend';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Tooltip, IconButton } from '@material-ui/core';
// core components
import GridItem from './components/GridItem.js';
import GridContainer from './components/GridContainer.js';
import Table from './components/Table.js';
import Card from './components/Card.js';
import CardHeader from './components/CardHeader.js';
import CardBody from './components/CardBody.js';

import CustomDatePicker from '../CustomDatePicker/CustomDatePicker.js';
import generateTaskStatus from '../../utils/generateTaskStatus';
import { emailsSubscriptionChart } from './variables/charts.js';
import { getOrders } from '../../actions/azdanActions';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

const useStyles = makeStyles(styles);

function TableList(props) {
  const classes = useStyles();
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [graphData, setGraphData] = useState({ label: [], series: [] });

  useEffect(() => {
    if (props.orders === null) {
      props.getOrders();
    }
  }, []);

  useEffect(() => {
    const data = generateChartData();
    setChartData(data);
  }, [props]);

  useEffect(() => {
    const graphData = generateGraphData();
    setGraphData(graphData);
  }, [chartData]);

  const generateGraphData = () => {
    const graphData = {
      labels: [],
      series: [],
    };
    const temp = [];
    chartData.sort((a, b) => {
      return Date.parse(a[0]) - Date.parse(b[0]);
    });

    chartData.forEach(row => {
      graphData.labels.push(row[0].split('-')[2]);
      temp.push(row[5]);
    });
    graphData.series.push(temp);

    return graphData;
  };

  const generateChartData = () => {
    const data = [];
    let toCollectSum = 0;
    let collectedSum = 0;

    let orderList = props.orders;
    if (orderList === null) {
      orderList = [];
    }
    orderList.forEach(order => {
      if (order.task_codamount) {
        data.push([
          order.task_completeafterdate,
          order.task_consigneename,
          //order number
          generateTaskStatus(order.task_status),
          order.task_conscountryname,
          order.task_conscity,
          order.task_codamount,
          order.task_collectedamt || 0,
          (order.task_codamount - (order.task_collectedamt || 0)).toFixed(2),
          getCollectionStatus(order),
        ]);
        toCollectSum += Number(order.task_codamount);
        collectedSum += Number(order.task_collectedamt || 0);
      }
    });
    setSummary([
      '',
      '',
      '',
      '',
      toCollectSum.toFixed(2),
      collectedSum.toFixed(2),
      (toCollectSum - collectedSum).toFixed(2),
      '',
    ]);

    return data;
  };

  const getCollectionStatus = order => {
    if (!order.task_collectedamt) {
      return 'Not Collected';
    } else if (order.task_codamount === order.task_collectedamt) {
      return 'Collected';
    } else {
      return 'Partial Payment';
    }
  };

  return (
    <>
      <div
        style={{ marginLeft: '-10px', display: 'flex', alignItems: 'center' }}
      >
        <CSVLink
          headers={[
            'Date',
            'Customer Name',
            'Status',
            'Country',
            'City',
            'To Collect',
            'Collected',
            'Pending',
            'Collect Status',
          ]}
          data={chartData}
          filename={'tcorp-COD-report.csv'}
          className="ml-2"
        >
          <Tooltip title="Download CSV" aria-label="Download CSV">
            <IconButton
              varient="contained"
              color="primary"
              size="medium"
              aria-label="export-csv"
              type="button"
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
        <CustomDatePicker />
      </div>
      <GridContainer style={{ height: '87vh', overflow: 'scroll' }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: graphData.labels,
                  series: graphData.series,
                }}
                type="Line"
                options={{
                  low: 0,
                  high: Math.max.apply(Math, graphData.series[0]),
                  plugins: [
                    Legend({
                      position: 'bottom',
                      legendNames: ['Payments Recieved'],
                    }),
                  ],
                }}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
              <h4 className={classes.cardTitleWhite}>COD Report</h4>
              <p className={classes.cardCategoryWhite}>
                Here is where you can view all of your cash payments accepted on
                delivery
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  'Date',
                  'Customer Name',
                  'Status',
                  'Country',
                  'City',
                  'To Collect',
                  'Collected',
                  'Pending',
                  'Collect Status',
                ]}
                tableData={chartData}
                footerData={summary}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = state => {
  return {
    orders: state.order.orderList,
  };
};

export default connect(mapStateToProps, { getOrders })(TableList);
