import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';

import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { Tooltip, IconButton } from '@material-ui/core';

import HideColumnsBtn from './HideColumnsBtn';
import ActiveOrderTabs from './ActiveOrderTabs';
import OrderSearchBar from './OrderSearchBar';
import { getOrders } from '../../actions/azdanActions';
import generateCsv from '../../utils/generateCSV';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

const TableNav = props => {
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    setCsvData(generateCsv(props.currentOrders, props.tableHeaders));
  }, [props.currentOrders]);

  return (
    <div className="collapse navbar-collapse pb-1" id="orderTableNavbar">
      {/* @csv download */}
      <CSVLink
        headers={props.tableHeaders}
        data={csvData}
        filename={'tcorp-orders.csv'}
        className="mx-2"
      >
        <Tooltip title="Download CSV" aria-label="Download CSV">
          <IconButton
            varient="contained"
            color="primary"
            size="medium"
            aria-label="export-csv"
            type="button"
          >
            <CloudDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </CSVLink>
      <HideColumnsBtn
        tableColumnsData={props.tableColumnsData}
        setColumnsHidden={props.setColumnsHidden}
      />
      <div
        className="d-flex flex-row filter-container ml-3 mr-2"
        style={{ height: '38px' }}
      >
        {/* @search category filter */}
        <OrderSearchBar
          id="nav_elm"
          onChangeSearchValue={props.onChangeSearchValue}
          searchValue={props.searchValue}
        />
        <CustomDatePicker />
      </div>
      <ActiveOrderTabs
        navigate={props.navigate}
        activeOrders={props.activeOrders}
        closedOrders={props.closedOrders}
        allOrders={props.allOrders}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    order: state.order,
  };
};

export default connect(mapStateToProps, { getOrders })(TableNav);
