const JsSearch = require('js-search');

export default (orders, searchQuery) => {
  const search = new JsSearch.Search('_id');
  search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
  for (let key in searchQuery) {
    if (key === '') {
      search.addDocuments(orders);
      search.addIndex('nstaskid');
      search.addIndex('task_consigneename');
      search.addIndex('task_consaddline1');
      search.addIndex('task_conscity');
      search.addIndex('task_consdistrict');
      search.addIndex('task_details');
      search.addIndex('task_shippaymentmethod');
      search.addIndex('task_notes');
      search.addIndex('task_conscontactphone');
      search.addIndex('task_awb');
      search.addIndex('task_customerorderno');
      return search.search(searchQuery[key]);
    } else {
      if (key === 'task_completeaftertime') {
        // 01/01/2011 is filler because date is required, but we're only using the time here
        orders = orders.filter(
          order =>
            Date.parse(`01/01/2011 ${order.task_completeaftertime}`) >=
            Date.parse(`01/01/2011 ${searchQuery[key]}`)
        );
      } else if (key === 'task_completebeforetime') {
        // 01/01/2011 is filler because date is required, but we're only using the time here
        orders = orders.filter(
          order =>
            Date.parse(`01/01/2011 ${order.task_completeaftertime}`) <=
            Date.parse(`01/01/2011 ${searchQuery[key]}`)
        );
      } else if (key === 'task_completeafterdate') {
        orders = orders.filter(
          order =>
            Date.parse(order.task_completeafterdate) ===
            Date.parse(searchQuery[key])
        );
      } else if (key === 'task_endtime') {
        orders = orders.filter(
          order =>
            Date.parse(order.task_endtime) === Date.parse(searchQuery[key])
        );
      } else if (key === 'task_status') {
        orders = orders.filter(order =>
          searchQuery[key].includes(order.task_status)
        );
      } else if (key === 'task_consrating') {
        orders = orders.filter(order =>
          searchQuery[key].includes(order.task_consrating)
        );
      } else {
        const searchTerms = searchQuery[key].map(term => term.toLowerCase());
        orders = orders.filter(order =>
          searchTerms.includes(order[key].toLowerCase())
        );
      }
    }
  }
  return orders;
};
