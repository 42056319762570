import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setOrders } from '../../actions/azdanActions';
import io from 'socket.io-client';

function Webhook(props) {
  const socket = io.connect(window.location.origin);

  const updateOrders = (task_id, task_status) => {
    const taskId = Number(task_id);
    const changedOrders = props.orders;
    changedOrders.map(order => {
      if (order.task_id === taskId) {
        order.task_status = task_status;
      }
    });
    props.setOrders(changedOrders);
  };

  useEffect(() => {
    socket.on('TASK_PICKED_UP', order => {
      if (props.orders) {
        updateOrders(order.task_id, '7');
      }
    });
    socket.on('TASK_OUT_FOR_DELIVERY', order => {
      if (props.orders) {
        updateOrders(order.task_id, '10');
      }
    });
    socket.on('TASK_COMPLETED', order => {
      if (props.orders) {
        updateOrders(order.task_id, '3');
      }
    });
    socket.on('TASK_FAILED', order => {
      if (props.orders) {
        updateOrders(order.task_id, '5');
      }
    });
    socket.on('TASK_CANCELED', order => {
      if (props.orders) {
        updateOrders(order.task_id, '4');
      }
    });
    // clean up effect
    return () => socket.disconnect();
  }, []);

  return null;
}

const mapStateToProps = state => {
  return {
    orders: state.order.orderList,
  };
};

export default connect(mapStateToProps, { setOrders })(Webhook);
