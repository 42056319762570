import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import ChartistGraph from "react-chartist";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { Tooltip, IconButton } from "@material-ui/core";
// core components
import GridItem from "./components/GridItem.js";
import GridContainer from "./components/GridContainer.js";
import Table from "./components/Table.js";
import Card from "./components/Card.js";
import CardHeader from "./components/CardHeader.js";
import CardBody from "./components/CardBody.js";
import CityFilter from "../FiltersSidedrawer/CityFilter";
// import deliveryImg from '../../images/delivery-man.png';
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker.js";
import { emailsSubscriptionChart } from "./variables/charts.js";
import { getOrders } from "../../actions/azdanActions";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

function TableList(props) {
  const classes = useStyles();
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [graphData, setGraphData] = useState({ label: [], series: [] });
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (props.orders === null) {
      props.getOrders();
    }
  }, []);

  useEffect(() => {
    const data = generateChartData();
    setChartData(data);
  }, [refresh, props]);

  useEffect(() => {
    const graphData = generateGraphData();
    setGraphData(graphData);
  }, [chartData]);

  const generateGraphData = () => {
    const graphData = {
      labels: [],
      series: [],
    };
    const temp = [];
    chartData.sort((a, b) => {
      return b[3] - a[3];
    });
    chartData.forEach((row) => {
      graphData.labels.push(row[1]);
      temp.push(row[2]);
    });

    graphData.series.push(temp);
    return graphData;
  };

  const filterByCityHandler = (city) => {
    if (!city[0]) {
      setRefresh(!refresh);
    }
    const filteredCities = chartData.filter((row) => row[0] === city[0]);
    setChartData(filteredCities);
  };

  const generateChartData = () => {
    const data = [];
    const districts = [];
    let sum = 0;

    let orderList = props.orders;
    if (orderList === null) {
      orderList = [];
    }

    orderList.forEach((order) => {
      let index = districts.indexOf(order.task_consdistrict);

      if (index < 0) {
        data.push([
          order.task_conscity,
          order.task_consdistrict,
          1,
          ((1 / props.orders.length) * 100).toFixed(1),
        ]);
        districts.push(order.task_consdistrict);
      } else {
        sum++;
        data[index][2]++;
        data[index][3] = ((data[index][2] / props.orders.length) * 100).toFixed(
          1
        );
      }
    });
    setSummary(["", sum, ""]);
    return data;
  };

  return (
    <>
      <div
        style={{ marginLeft: "-10px", display: "flex", alignItems: "center" }}
      >
        <CSVLink
          headers={["City", "Area", "Orders", "Percentage"]}
          data={chartData}
          filename={"tcorp-breakdown-report.csv"}
          className="ml-2"
        >
          <Tooltip title="Download CSV" aria-label="Download CSV">
            <IconButton
              varient="contained"
              color="primary"
              size="medium"
              aria-label="export-csv"
              type="button"
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
        <CustomDatePicker />
      </div>
      <GridContainer style={{ height: "87vh", overflow: "scroll" }}>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart order-breakdown-chart"
                data={{
                  labels: graphData.labels,
                  series: graphData.series,
                }}
                type="Bar"
                options={{
                  low: 0,
                  high: Math.max.apply(Math, graphData.series[0]),
                }}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
              <div className="d-flex justify-content-between align-items-end mt-5">
                <div>
                  <h4 className={classes.cardTitleWhite}>Order Breakdown</h4>
                  <p className={classes.cardCategoryWhite}>
                    Here you can view a breakdown of your orders by
                    District/Area
                  </p>
                </div>
                <div className="breakdown-report-filter">
                  <CityFilter
                    handleCity={filterByCityHandler}
                    cities={chartData.map((data) => data[0])}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["City", "Area", "Orders", "Percentage"]}
                tableData={chartData}
                footerData={summary}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    orders: state.order.orderList,
  };
};

export default connect(mapStateToProps, { getOrders })(TableList);
