import {
  GET_CSV_ERRORS,
  GET_CSV_HEADER_ERRORS,
  GET_ORDER_ERRORS,
  GET_ERRORS,
  CLEAR_ERRORS,
  USER_LOGOUT,
  GET_LOGIN_ERRORS,
} from '../actions/types';

const initialState = {
  csvErrors: '',
  csvErrorData: '',
  orderErrors: {},
  loginErrors: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };

    case GET_CSV_ERRORS:
      return {
        ...state,
        csvErrors: [...action.payload.error],
        csvErrorData: action.payload.csvErrorData,
      };

    case GET_CSV_HEADER_ERRORS:
      return {
        ...state,
        csvErrors: action.payload,
      };

    case GET_ORDER_ERRORS:
      return {
        ...state,
        orderErrors: action.payload,
      };

    case GET_LOGIN_ERRORS:
      return {
        ...state,
        loginErrors: action.payload,
      };

    case CLEAR_ERRORS:
      return initialState;

    case USER_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
