import React from 'react';
import { Route } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import reports from './reportRoutes';
import deliveryImg from '../../images/delivery-man.png';

import DashboardPage from './Dashboard';
import CODReport from './CODReport';
import InventoryReport from './InventoryReport';
import ClientOverviewReport from './ClientOverviewReport';
import OrderReport from './OrderBreakdownReport';
import EmailReports from './EmailReports';

const Reports = () => {
  return (
    <>
      <Sidebar color={'blue'} image={deliveryImg} reports={reports} />
      <div
        style={{
          margin: '10px 20px 30px 300px',
          width: '80%',
        }}
      >
        <Route exact path="/reports/dashboard" component={DashboardPage} />
        <Route
          exact
          path="/reports/inventoryReport"
          component={InventoryReport}
        />
        <Route exact path="/reports/orderReport" component={OrderReport} />
        <Route
          exact
          path="/reports/overviewReport"
          component={ClientOverviewReport}
        />
        <Route exact path="/reports/codReport" component={CODReport} />
        <Route exact path="/reports/emailReports" component={EmailReports} />
      </div>
    </>
  );
};

export default Reports;
