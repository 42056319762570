import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Spinner from '../common/Spinner';
import ActivityList from './ActivityList';
import { logoutUser } from '../../actions/authActions';
import { getOrders } from '../../actions/azdanActions';
import Card from '../Reports/components/Card.js';
import CardHeader from '../Reports/components/CardHeader.js';
import GridItem from '../Reports/components/GridItem.js';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

// 1 Unassigned
// 2 Assigned
// 3 Completed
// 4 Cancelled
// 5 Failed
// 6 In Transit
// 7 Picked up
// 9 Arrived in DC
// 10 Out for Delivery
// 15 Process for return
// 16 Returned to Shipper

const activityContainer = {
  height: '90vh',
  overflow: 'scroll',
  width: '20%',
  flexBasis: 'auto',
  maxWidth: '20%',
};

const Activity = props => {
  useEffect(() => {
    // Logout if token has expired
    const currentTime = Date.now() / 1000;
    if (props.auth.user.exp < currentTime) {
      props.logoutUser();
    }
    if (props.order.orderList === null) {
      props.getOrders();
    }
  }, []);

  const { order } = props;
  const unassignedOrders = [];
  const assignedOrders = [];
  const inTransitOrders = [];
  const completeOrders = [];
  const failedOrders = [];
  let orderList = order.orderList;

  if (orderList === null) {
    orderList = [];
  }

  for (let i = 0; i < orderList.length; i++) {
    if (orderList[i].task_status === '1') {
      unassignedOrders.push(orderList[i]);
    } else if (orderList[i].task_status === '2') {
      assignedOrders.push(orderList[i]);
    } else if (
      orderList[i].task_status === '6' ||
      orderList[i].task_status === '10' ||
      orderList[i].task_status === '17'
    ) {
      inTransitOrders.push(orderList[i]);
    } else if (
      orderList[i].task_status === '3' ||
      orderList[i].task_status === '7'
    ) {
      completeOrders.push(orderList[i]);
    } else if (
      orderList[i].task_status === '5' ||
      orderList[i].task_status === '15' ||
      orderList[i].task_status === '16'
    ) {
      failedOrders.push(orderList[i]);
    }
  }

  let content;
  if (order.submitInProgress) {
    content = <Spinner />;
  } else {
    content = (
      <div className="fade-in activity">
        <div style={{ marginLeft: '6px' }}>
          <CustomDatePicker />
        </div>
        <div className="row">
          <GridItem xs={2} sm={2} md={2} style={activityContainer}>
            <Card>
              <CardHeader color="warning">
                <h5>{unassignedOrders.length} - Unassigned</h5>
              </CardHeader>
              <ActivityList orders={unassignedOrders} />
            </Card>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} style={activityContainer}>
            <Card>
              <CardHeader color="info">
                <h5>{assignedOrders.length} - Assigned</h5>
              </CardHeader>
              <ActivityList orders={assignedOrders} />
            </Card>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} style={activityContainer}>
            <Card>
              <CardHeader color="primary">
                <h5>{inTransitOrders.length} - In Transit</h5>
              </CardHeader>
              <ActivityList orders={inTransitOrders} />
            </Card>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} style={activityContainer}>
            <Card>
              <CardHeader color="success">
                <h5>{completeOrders.length} - Complete</h5>
              </CardHeader>
              <ActivityList orders={completeOrders} />
            </Card>
          </GridItem>
          <GridItem xs={2} sm={2} md={2} style={activityContainer}>
            <Card>
              <CardHeader color="danger">
                <h5>{failedOrders.length} - Failed</h5>
              </CardHeader>
              <ActivityList orders={failedOrders} />
            </Card>
          </GridItem>
        </div>
      </div>
    );
  }

  return <div className="mx-3 mobile-margin">{content}</div>;
};

const mapStateToProps = state => ({
  order: state.order,
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser, getOrders })(Activity);
